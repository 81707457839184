import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  },
);

const HiddenColumns = ({ display, columns, getToggleHideAllColumnsProps }) => (
  <>
    <div className={`modal fade ${display ? 'show' : ''}`} id="columnSelector" tabIndex="-1" role="dialog" aria-labelledby="columnSelectorLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="columnSelectorLabel">Sélection des colonnes visibles</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            <div className="row">
              <div className="col-12">
                <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
                Tout basculer
              </div>
              {columns.map((column) => (
                <div
                  key={column.id}
                  {...column.getToggleHiddenProps()}
                  className="col-6"
                >
                  <input
                    name={`display-${column.id}`}
                    {...column.getToggleHiddenProps()}
                    type="checkbox"
                  />
                  <label
                    htmlFor={`display-${column.id}`}
                  >
                    {' '}
                    {column.id}
                  </label>
                </div>
              ))}
              <br />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-dismiss="modal">Terminé</button>
          </div>
        </div>
      </div>
    </div>
  </>
);

HiddenColumns.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getToggleHideAllColumnsProps: PropTypes.func.isRequired,
};

export default HiddenColumns;
