import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import GeoJSON from 'ol/format/GeoJSON';
import Vector from 'ol/source/Vector';
import VectorLayer from '../layers/vector';
import { MapContext } from '../../Map';

const JSONLayer = ({ url, features, ...other }) => {
  const map = useContext(MapContext);
  const [source, setSource] = useState();

  const setFeatures = (features) => {
    if(features){
      setSource(new Vector({
        features: (new GeoJSON()).readFeatures({
          type: 'FeatureCollection',
          features,
        }, {
          featureProjection: map.getView().getProjection(),
        }),
      }));
    }
  }
  useEffect(() => {
    if(url){
      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          setFeatures(
            json.records.map((r) => ({
              type: 'Feature',
              geometry: r.fields.geo_shape,
              properties: r.fields,
            }))
          );
        });
      }
  }, [url]);

  useEffect(() => {
    setFeatures(features)
  }, [features])

  return <VectorLayer source={source} {...other} />;
};

JSONLayer.propTypes = {
  url: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.shape())
};

JSONLayer.defaultProps = {
  url: undefined,
  features: [],
};

export default JSONLayer;
