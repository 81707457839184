import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RelationSummary from './RelationSummary';
import TableSearch from '../Table/Search';

const RelationManager = ({
  authenticityToken,
  layer,
  featuresCount,
  orphansCount,
  orphansPercent,
  featureAttributes,
}) => {
  const [groupByAttribute, setGroupByAttribute] = useState();
  const [attributeFilters, setAttributeFilters] = useState([]);

  const setAttributeFilter = (e) => {
    setGroupByAttribute(e.target.value);
  };

  return (
    <>
      <h2>Résumé</h2>

      <div className="row">
        <div className="col-4">
          Infrastructures orphelines :
        </div>
        <div className="col-8">
          {orphansCount}
          {' / '}
          {featuresCount}
          (
          {orphansPercent}
          %)
        </div>
      </div>

      <h2>Assignation</h2>
      <div>
        Recherche d&apos;infrastructure par :
        <select
          name="feature_attribute"
          className="form-control"
          onChange={setAttributeFilter}
          value={groupByAttribute}
        >
          {featureAttributes.sort().map((attr) => (
            <option value={attr} key={attr}>{attr}</option>
          ))}
        </select>
      </div>
      <TableSearch attributes={featureAttributes} onChange={setAttributeFilters} />
      <RelationSummary
        authenticityToken={authenticityToken}
        layer={layer}
        groupByAttribute={groupByAttribute}
        attributeFilters={attributeFilters}
      />
    </>
  );
};

RelationManager.propTypes = {
  layer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  orphansCount: PropTypes.number,
  orphansPercent: PropTypes.number,
  featuresCount: PropTypes.number,
  authenticityToken: PropTypes.string.isRequired,
  featureAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

RelationManager.defaultProps = {
  orphansCount: 0,
  orphansPercent: 0,
  featuresCount: 1,
};

export default RelationManager;
