import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Map from './Map';
import NavigationBar from './NavigationBar';
import Axios from 'axios';
import ForecastContext from './Forecasts/Context';
import { Toast } from './Toast';
import ForecastUpdateNotifier from './ForecastUpdateNotifier';
import { LayersProvider } from './ol/LayersContext';
import I18n from 'i18n-js'

const MapContainer = ({ id }) => {
  const [map, setMap] = useState({ layers: [] })
  const [currentForecast, setCurrentForecast] = useState()
  const [currentForecastId, setCurrentForecastId] = useState('latest')

  const [disabledIntervals, setDisabledIntervals] = useState([])
  const [ isObsolete, setIsObsolete ] = useState(true)
  const [showFunctional, setShowFunctional] = useState(false)

  useEffect(() => {
    Axios.get(`/maps/${id}.json`)
      .then(res => {
        setMap({
          ...res.data,
          layers: res.data.layers,
          layerAggregations: [],
        })
      })
      .catch(err => {
        console.error(err)
        Toast.error({ title: I18n.t('error'), text: I18n.t('Could not load map') })
      })
  }, [id])


  const loadForecast = (forecastId) => {
    if (forecastId) {
      return fetch(`/forecast_bulletins/${forecastId}.json`)
        .then((response) => response.json())
        .then((updatedForecast) => {
          setCurrentForecast(updatedForecast)
          setIsObsolete(false)
        })
        .catch(() => setCurrentForecast(null))
    }
    return Promise.resolve()
  }

  useEffect(() => {
    loadForecast(currentForecastId)
  }, [currentForecastId])
  return (
    <React.StrictMode>
      <LayersProvider layers={map.layers}>
        <ForecastContext.Provider value={
          {
            currentForecast, setCurrentForecastId, setCurrentForecast,
            setDisabledIntervals, disabledIntervals,
            setIsObsolete, isObsolete,
            showFunctional, setShowFunctional,
            loadForecast
          }
        }>
          <meta name="turbolinks-visit-control" content="reload"></meta>

          <ForecastUpdateNotifier />
          <div className="map-container">
            <NavigationBar layers={map?.layers} layerAggregations={map?.layerAggregations} mapDisplayed={true} />
            <div id="right-panel" className="right-panel">
              <Map
                name="base"
                map={map}
              />
            </div>
          </div>
        </ForecastContext.Provider>
      </LayersProvider>
    </React.StrictMode>

  );
};

MapContainer.propTypes = {
  id: PropTypes.number,
};

MapContainer.defaultProps = {
  id: null,
};

export default MapContainer;
