import React, { useEffect, useContext, useState } from 'react';
import Image from 'ol/layer/Image';
import PropTypes from 'prop-types';
import { MapContext } from '../mapContext';
import { addCallbacksToLayer, restoreLayerAttributes } from '../../LayerStore';

const ImageLayer = ({
  source, isVisible: visible, opacity, name, uuid, type, id, ...others
}) => {
  const map = useContext(MapContext);
  const [layerSource, setLayerSource] = useState();

  const addSource = () => {
    if (source) {
      if (!layerSource) {
        const imageLayer = new Image({
          id,
          source,
          visible,
          opacity,
          name,
          uuid,
          type,
          ...others,
        });
        restoreLayerAttributes(imageLayer)
        addCallbacksToLayer(imageLayer)
        setLayerSource(imageLayer);
        map.addLayer(imageLayer);

      } else {
        layerSource.setSource(source)
      }
    }
  };
  useEffect(() => {
    addSource();
  }, [source]);

  return null;
};

ImageLayer.propTypes = {
  visible: PropTypes.bool,
  opacity: PropTypes.number,
  name: PropTypes.string.isRequired,
};

ImageLayer.defaultProps = {
  // visible: visible,
  opacity: 1,
};

export default ImageLayer;
