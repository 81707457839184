import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableSearch from '../Table/Search';
import Table from '../Table';

const FeatureTable = ({ features, attributeTypes }) => {
  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchParams, setSearchParams] = useState();
  const [groupParams, setGroupParams] = useState();

  const hideColumn = (column) => {
    setHiddenColumns([...hiddenColumns, column]);
  };

  const showColumn = (column) => {
    setHiddenColumns(hiddenColumns.filter((c) => c !== column));
  };
  const columns = React.useMemo(
    () => (features.length
      ? Object.keys(features[0])
        .filter((attr) => hiddenColumns.indexOf(attr) < 0)
        .map((attr) => (
          {
            Header: attr,
            accessor: attr, // accessor is the "key" in the data
          }))
      : []),
    [hiddenColumns, groupParams],
  );

  useEffect(() => {
    if (pageNumber) {
      const {
        index, size, sortBy, groupBy,
      } = pageNumber;
      axios.get(`${window.location.href}.json`, {
        params: {
          page: index,
          per_page: size,
          hide: hiddenColumns,
          group_by: groupBy,
          q: { s: sortBy, ...searchParams },
        },
      })
        .then((res) => {
          setData(res.data.data);
          setPageCount(res.data.total_pages);
          setLoading(false);
        });
    }
  }, [pageNumber, searchParams, groupParams]);

  const fetchData = React.useCallback(({
    pageSize, pageIndex, sortBy, groupBy,
  }) => {
    setLoading(true);
    setPageNumber({
      index: pageIndex, size: pageSize, sortBy, groupBy,
    });
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <ul>
        {hiddenColumns.map((c) => <li key={c} onClick={() => showColumn(c)}>{c}</li>)}
      </ul>
      <TableSearch attributeTypes={attributeTypes} onChange={setSearchParams} />
      <Table
        columns={columns}
        data={data}
        onFetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        attributeTypes={attributeTypes}
        hideColumn={hideColumn}
        groupBy={groupParams}
      />
    </div>
  );
};

export default FeatureTable;
