import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import Criteria from './Criteria';
import Axios from 'axios';
import usePortal from "react-cool-portal";
import axios from 'axios'
import qs from 'qs'
import Comparators from './comparators'
import Result from './Table'

const TableSearch = ({
  attributeTypes: types,
  criterias: originalCriterias,
  onChange: onChangeFilters,
  onSearch,
  onSubmit,
  layerId,
  key,
}) => {
  const [formId, setFormId] = useState(key || `form-${Math.random()}`)
  const [attributeTypes, setAttributeTypes] = useState(types)
  const [searchResult, setSearchResult] = useState(false)
  const [criterias, setCriterias] = useState(originalCriterias || [])
  const [pagination, setPagination] = useState({ per_page: 10, page: 1 })
  const [searchOptions, setSearchOptions] = useState({
    pagination: { per_page: 10, page: 1 }
  })
  const { Portal } = usePortal();

  useEffect(() => {
    if (!attributeTypes && layerId) {
      Axios.get(`/layers/${layerId}/features/definition.json`)
        .then(res => setAttributeTypes(res.data))
    }
  }, [attributeTypes, layerId])

  useEffect(() => {
    if (criterias.length) {
      if (onSearch) { return onSearch(criterias); }
      axios.get(`/layers/${layerId}/search?${qs.stringify({ q: criterias, ...searchOptions })}`)
        .then(res => setSearchResult(res.data))
    }
  }, [criterias, pagination, searchOptions])

  const doSearch = ({ criterias: formValues }) => setCriterias(formValues)

  return (
    <>
      <Formik
        initialValues={{
          criterias
        }}
        onSubmit={({ criterias }, { setSubmitting }) => {
          if (onSubmit) { onSubmit(criterias); }
          setSubmitting(false);
        }}
        handleChange={(a, b, c) => {
          onChangeFilters(a, b, c)
        }}
        enableReinitialize
      >
        {({
          handleSubmit, values,
        }) => {
          const { criterias } = values;
          // useEffect(() => {
          //   onChangeFilters(criterias)
          // }, [values])
          return <>

            <Portal>
              <form id={formId} onSubmit={handleSubmit} className="my-3" />
            </Portal>

            <FieldArray
              name="criterias"
              render={(arrayHelpers) => {
                const addCriteria = () => arrayHelpers.push({
                  attributeName: '',
                  attributeValue: '',
                  comparator: '==',
                });

                if (!attributeTypes) {
                  return 'LOADING'
                }

                return (<>
                  <div>
                    {criterias && criterias.length > 0 ? (
                      <>
                        {criterias
                          .map((criteria, index) => (
                            !criteria._destroy &&
                            <div className="row" key={`criteria-${criteria.id}`}>
                              <div className="col-11">
                                <Criteria
                                  index={index}
                                  attributeTypes={attributeTypes}
                                  form={formId}
                                  onChange={onChangeFilters}
                                />
                              </div>
                              <div className="col-1 m-auto text-center">
                                <i
                                  type="button"
                                  aria-label="Supprimer condition"
                                  className="fas fa-trash-alt btn btn-link text-danger mx-2"
                                  onClick={() => arrayHelpers.replace(index, { _destroy: true, id: criteria.id })}
                                  onKeyPress={() => arrayHelpers.replace(index, { _destroy: true, id: criteria.id })}
                                  role="button"
                                  tabIndex={0}
                                />
                              </div>
                            </div>
                          ))}
                      </>
                    ) : null}
                    {criterias && criterias.length > 0
                      ? (
                        <>
                          <div className="row mt-2">
                            <div className="col text-right">
                              <button
                                form={formId}
                                type="button"
                                className="btn btn-success"
                                onClick={addCriteria}
                              >
                                Ajouter une condition
                                  <i className="fas fa-plus-circle ml-2" />
                              </button>
                            </div>
                          </div>
                          <div className="row mt-2 text-right">
                            <div className="col">
                              <button type="button"
                                className="btn btn-primary"
                                form={formId}
                                onClick={() => doSearch(values)}
                              >
                                Recherche
                                </button>
                            </div>
                          </div>
                        </>
                      )
                      : 'EMPTY'}
                  </div>
                  {
                    searchResult
                      ? <Result
                        result={searchResult}
                        attributeTypes={attributeTypes}
                        onSearchUpdate={setSearchOptions}
                      />
                      : null
                  }
                </>);
              }}
            />
          </>
        }}
      </Formik>
    </>
  );
};

TableSearch.propTypes = {
  attributeTypes: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  layerId: PropTypes.number,
  criterias: PropTypes.arrayOf(PropTypes.shape({
    attributeName: PropTypes.string.isRequired,
    comparator: PropTypes.oneOf(Comparators.map(c => c.value)),
    attributeValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }))
};

TableSearch.defaultProps = {
  attributeTypes: null,
  layerId: null,
  criterias: [{
    attributeName: '',
    attributeValue: '',
    comparator: '==',
  }]
}

export default TableSearch;
