import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const DependencyConfig = ({ layerId, id }) => {
  const csrfToken = document.querySelector('[name=csrf-token]').content;
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  const [dependencyConfig, setDependencyConfig] = useState();
  useEffect(() => {
    axios
      .get(`/layers/${layerId}/dependencies/${id || 'new'}.json`)
      .then((res) => {
        setDependencyConfig(res.data);
      })
      .catch(() => {
        setDependencyConfig(null);
      });
  }, [id]);

  if (typeof dependencyConfig === 'undefined') {
    return null;
  }
  if (dependencyConfig === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Couche de données indéterminée !
      </div>
    );
  }
  return (
    <>
      <h2 className="mb-4">
        {`Dépendence envers ${dependencyConfig.dependency.name}`}
      </h2>

      <div className="row">

        <div className="col-5">
          <div className="card">
            <div className="card-header text-white bg-primary">
              <h6>{dependencyConfig.layer.name}</h6>
            </div>
            <div className="card-body">
              <h6>{dependencyConfig.featureAttribute}</h6>
            </div>
          </div>
        </div>

        <div className="col-2 text-center my-auto">
          <div>
            Correspond à
              </div>
          <div>
            <i style={{ fontSize: '4em' }} className="fas fa-long-arrow-alt-right" />
          </div>
        </div>


        <div className="col-5">
          <div className="card">
            <div className="card-header text-white bg-warning">
              <h6>{dependencyConfig.dependency.name}</h6>
            </div>
            <div className="card-body">
              <h6>{dependencyConfig.dependencyAttribute}</h6>
            </div>
          </div>
        </div>

      </div>
      <div className="row">
        <div className="col-6 text-left">
          <a
            className="btn btn-link"
            href={`/layers/${layerId}/dependencies`}
          >
            <i className="fas fa-chevron-left mr-2" />
            Retour à la liste des dépendences
          </a>
        </div>
        <div className="col-6 text-right">
          <a
            className="btn btn-warning mr-2"
            href={`/layers/${layerId}/dependencies/${id}/requirements`}
          >
            Configurer les exigences
          </a>
          <a
            className="btn btn-primary"
            href={`/layers/${layerId}/dependencies/${id}/edit`}
          >
            Éditer
          </a>
        </div>
      </div>
    </>
  );
};

DependencyConfig.propTypes = {
  layerId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default DependencyConfig;
