import React, { useState } from "react";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import axios from "axios";
import { layerFeatureEvaluator } from "../../types/LayerFeatureEvaluator";
import EvaluatorForm from "../LayerFeatureEvaluator/Form";
import I18n from "i18n-js"

interface Props {
  evaluator: layerFeatureEvaluator;
  frameId: string;
  forecastedlayerId: number;
}

const LayerFeatureEvaluatorFrame = (props: Props) => {
  const [showEditEvaluatorModal, setShowEditEvaluatorModal] = useState<boolean>(false);

  // const dispatch = useAppDispatch();

  const handleDeleteEvaluator = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    axios.delete(`/forecastable_models/${props.evaluator.forecastableModelId}/layer_feature_evaluators/${props.evaluator.id}.json`)
      .then(_response => {
        window.location.reload();
      })
    return false;
  }

  const evaluatorPopOver = (
    <Popover id="forecastableModelPopOver">
      <Popover.Title as="h3">Évaluateur</Popover.Title>
      <Popover.Content>
        <p>
          Consulter ou modifier un évaluateur.

          Un évaluateur fait le lien entre infrastructure(feature) et prévision à partir d’une variable.
        </p>
      </Popover.Content>
    </Popover>
  )

  const deleteEvaluatorPopOver = (
    <Popover id="forecastableModelPopOver">
      <Popover.Title as="h3">Évaluateur</Popover.Title>
      <Popover.Content>
        <p>
          Supprimer un évaluateur.

          Un évaluateur fait le lien entre infrastructure(feature) et prévision à partir d’une variable.


        </p>
      </Popover.Content>
    </Popover>
  )

  return (
    <div className="bg-secondary rounded-pill p-3" id={props.frameId}>
      <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={evaluatorPopOver}>

        <Button className="rounded-pill btn-secondary" onClick={_ => setShowEditEvaluatorModal(true)}>
          {
            `Évaluateur ${I18n.t(props.evaluator.type)}`
          }
        </Button>
      </OverlayTrigger>

      <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={deleteEvaluatorPopOver}>
        <button
          className="badge badge-pill badge-danger zindex-popover"
          onClick={_e => handleDeleteEvaluator(_e)}
        >
          X
        </button>
      </OverlayTrigger>

      <Modal show={showEditEvaluatorModal} onHide={() => setShowEditEvaluatorModal(false)} size={"xl"} >
        <EvaluatorForm forecastableModelId={props.evaluator.forecastableModelId} layerFeatureEvaluatorId={props.evaluator.id} forecastedLayerId={props.forecastedlayerId} />
      </Modal>
    </div>
  );
};

export default LayerFeatureEvaluatorFrame;
