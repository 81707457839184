import React from 'react'
import { useFormikContext, Field, FieldArray } from 'formik';
import { FieldWithError } from '../../Form/Error';
import { useEffect } from 'react';
import Axios from 'axios';
import { useState } from 'react';
import FeatureSearch from '../../Features/Search'

const NEW_RESOURCE = {
  name: '',
  quantity: 1,
  resourceType: '',
  featureMapsAttributes: [],
}

const ActionPlanResourceList = () => {
  const { values, errors, setFieldValue, getFieldValue } = useFormikContext();


  const [potentialResources, setPotentialResources] = useState([])
  const [currentResourceConditionShown, setCurrentResourceConditionShown] = useState()

  useEffect(() => {
    if (values.layerId) {
      Axios.get(`/layers/${values.layerId}/potential_resources.json`)
        .then(res => setPotentialResources(res.data))
    }
  }, [values.layerId])

  return (
    <FieldArray
      name="actionPlanResourcesAttributes"
      render={arrayHelpers => (
        <table className="table table-striped">
          <thead className={errors.actionPlanResources ? 'bg-danger' : 'thead-dark'}>
            <tr>
              <th>Intitulé</th>
              <th>Type</th>
              <th>Quantité</th>
              <th>Conditions</th>
              <th scope="col" colSpan="3"></th>
            </tr>
          </thead>
          <tbody>
            {errors.actionPlanResources
              ? <tr>
                <td colSpan={8}>
                  <ul>
                    {(errors.actionPlanResources || []).map(err => (
                      <li className="text-danger" key={`global-error-resources-${err}`}>{err}</li>
                    ))}
                  </ul>
                </td>
              </tr>
              : (values.actionPlanResourcesAttributes)
                .filter(resource => !resource._destroy)
                .map((resource, resourceIndex) => (
                  <tr key={`resource-${resource.id || `new-${resourceIndex}`}`}>
                    <td> <FieldWithError placeholder="Intitulé" className='form-control' name={`actionPlanResourcesAttributes[${resourceIndex}].name`}
                      errorName={`actionPlanResourcesAttributes[${resourceIndex}].name`} />
                    </td>
                    <td>
                      <Field type="hidden" name={`actionPlanResourcesAttributes[${resourceIndex}].id`} />
                      <FieldWithError
                        placeholder="Type de ressource"
                        className='form-control'
                        name={`actionPlanResourcesAttributes[${resourceIndex}].resourceType`}
                        errorName={`actionPlanResourcesAttributes[${resourceIndex}].resourceType`}
                        as="select"
                      >
                        <option disabled value="">Type de ressource</option>
                        {potentialResources.map(attr => (
                          <option value={attr.name} key={`resource-type-${attr.name}`}>{attr.name}</option>
                        ))}
                      </FieldWithError>
                    </td>
                    <td> <FieldWithError placeholder="Quantité" className='form-control' type="number" name={`actionPlanResourcesAttributes[${resourceIndex}].quantity`}
                      errorName={`actionPlanResourcesAttributes[${resourceIndex}].quantity`} />
                    </td>
                    <td>
                      <button className="btn btn-primary" type="button" onClick={() => setCurrentResourceConditionShown(resourceIndex)}>
                        Filtres
                        <span className="ml-1 badge badge-light">
                          {values.actionPlanResourcesAttributes[resourceIndex]
                            .featureMapsAttributes
                            .filter(criteria => !criteria._destroy)
                            .length}
                        </span>
                      </button>
                      {currentResourceConditionShown === resourceIndex
                        ? <FeatureSearch
                          layerId={values.layerId}
                          criterias={values.actionPlanResourcesAttributes[resourceIndex].featureMapsAttributes}
                          modal
                          onCloseModal={() => setCurrentResourceConditionShown(null)}
                          onChange={({ criterias }) => {
                            setFieldValue(`actionPlanResourcesAttributes[${resourceIndex}].featureMapsAttributes`, criterias)
                          }} />
                        : null}
                    </td>
                    <td>
                      <a onClick={() => arrayHelpers.replace(resourceIndex, { _destroy: true, id: resource.id }) /* arrayHelpers.remove(resourceIndex) */}><i className='fas fa-trash text-danger'></i></a>
                    </td>
                  </tr>
                ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8} className="text-right">
                <button type="button" className="btn btn-success" onClick={() => arrayHelpers.push(NEW_RESOURCE)}>
                  Ajouter une ressource requise
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      )} />
  )
}

export default ActionPlanResourceList