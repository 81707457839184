import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import Criteria from './Criteria';
import Axios from 'axios';
import Comparators from './comparators'

const TableSearch = ({
  attributeTypes: types,
  ransackValues,
  onChange: onChangeFilters,
  onSubmit,
  layerId,
}) => {
  const [attributeTypes, setAttributeTypes] = useState(types)
  
  useEffect(() => {
    if (!attributeTypes && layerId) {
      Axios.get(`/layers/${layerId}/features/definition.json`)
        .then(res => setAttributeTypes(res.data))
    }
  }, [attributeTypes, layerId])

  return (
    <Formik
      initialValues={{
        criterias: ransackValues
      }}
      onSubmit={({ criterias }, { setSubmitting }) => {
        if(onSubmit){ onSubmit(criterias); }
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        handleSubmit, values,
      }) => {
        const { criterias } = values;
        useEffect(() => {
          onChangeFilters(criterias)
        }, [values])
        return <form onSubmit={handleSubmit} className="my-3">

          <FieldArray
            name="criterias"
            render={(arrayHelpers) => {
              const addCriteria = () => arrayHelpers.push({
                id: criterias.length,
                attribute: '',
                comparator: 'eq',
                value: '',
              });

              if (!attributeTypes) {
                return 'LOADING'
              }

              return (
                <div>
                  {criterias && criterias.length > 0 ? (
                    <>
                      {criterias.map((criteria, index) => (
                        <div className="row" key={`criteria-${criteria.id}`}>
                          <div className="col-11">
                            <Criteria
                              id={index}
                              attributeTypes={attributeTypes}
                            />
                          </div>
                          <div className="col-1 m-auto text-center">
                            <i
                              type="button"
                              aria-label="Supprimer condition"
                              className="fas fa-trash-alt btn btn-danger mx-2"
                              onClick={() => arrayHelpers.remove(index)}
                              onKeyPress={() => arrayHelpers.remove(index)}
                              role="button"
                              tabIndex={0}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                  {criterias && criterias.length > 0
                    ? (
                      <>
                        <div className="row mt-2">
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-success float-right"
                              onClick={addCriteria}
                            >
                              Ajouter une condition
                              <i className="fas fa-plus-circle ml-2" />
                            </button>

                            <button type="submit" className="btn btn-primary">
                              Recherche
                            </button>
                          </div>
                        </div>
                      </>
                    )
                    : 'EMPTY'}
                </div>
              );
            }}
          />
        </form>
      }}
    </Formik>
  );
};

TableSearch.propTypes = {
  attributeTypes: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  layerId: PropTypes.number,
  ransackValues: PropTypes.arrayOf(PropTypes.shape({
    attribute: PropTypes.string.isRequired,
    comparator: PropTypes.oneOf(Comparators.map(c => c.value)),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }))
};

TableSearch.defaultProps = {
  attributeTypes: null,
  layerId: null,
  ransackValues: [{
    id: 0,
    attribute: '',
    comparator: 'eq',
    value: '',
  }]
}

export default TableSearch;
