import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import COMPARATORS from './comparators';

const FeatureConfigForm = ({ layerId, dependencyConfigId, id }) => {
  if (!layerId || !dependencyConfigId) { throw new Error('Missing paramemeters'); }

  const [requirement, setRequirement] = useState();
  const [dependencyConfig, setDependencyConfig] = useState();
  useEffect(() => {
    axios
      .get(`/layers/${layerId}/dependencies/${dependencyConfigId}/requirements/${id || 'new'}.json`)
      .then((res) => {
        const req = res.data;
        Object.keys(req).forEach((attr) => {
          req[attr] = req[attr] || '';
        });
        setRequirement(req);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`/layers/${layerId}/dependencies/${dependencyConfigId}.json`)
      .then((res) => setDependencyConfig(res.data));
  }, [dependencyConfigId]);

  if (!dependencyConfig || !requirement) {
    return null;
  }
  return (
    <>
      <h2 className="mb-4">
        Dépendences &gt;
        {' '}
        {requirement.id
          ? `Exigence envers ${dependencyConfig.dependency.name}`
          : 'Nouvelle exigence'}
      </h2>
      <div className="row">
        <div className="col-5">
          <div className="card">
            <div className="card-header text-white bg-primary mb-3">
              <h3>{dependencyConfig.layer.name}</h3>
            </div>
            <div className="card-body">
              <h6>{requirement.featureAttribute}</h6>
            </div>
          </div>
        </div>

        <div className="col-2 text-center my-auto">
          <div>
            Fonctionnel si
        </div>
          <div>
            <i style={{ fontSize: '4em' }} className="fas fa-long-arrow-alt-right" />
          </div>
          <div>
            <h6>{COMPARATORS[requirement.comparator]}</h6>
          </div>
        </div>

        <div className="col-5">
          <div className="card">
            <div className="card-header text-white bg-warning mb-3">
              <h3>{dependencyConfig.dependency.name}</h3>
            </div>
            <div className="card-body">
              <h6>{requirement.dependencyVariable.name}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 text-left">
          <a
            className="btn btn-link"
            href={`/layers/${layerId}/dependencies/${dependencyConfig.id}/requirements`}
          >
            <i className="fas fa-chevron-left mr-2" />
            Retour à la liste des exigences
          </a>
        </div>
        <div className="col-6 text-right">
          <a
            className="btn btn-warning"
            href={`${window.location.href}/edit`}
          >
            Éditer
          </a>
        </div>
      </div>
    </>
  );
};

FeatureConfigForm.propTypes = {
  layerId: PropTypes.number.isRequired,
  dependencyConfigId: PropTypes.number.isRequired,
  id: PropTypes.number,
};

FeatureConfigForm.defaultProps = {
  id: null,
};

export default FeatureConfigForm;
