import React, { useEffect, useState, useContext, useMemo } from 'react';
import Axios from 'axios'
import { MapContext } from './mapContext';
import LayerConfigs from './LayersContext';
import SidebarFeatureInfo from '../FeatureInfo/Sidebar';
import FeatureHighlight from '../Features/Highlight';
import Feature from 'ol/Feature';
import FeatureContextMenu from '../Features/ContextMenu'
import { ForecastContext } from '../Forecasts';
import { unByKey } from 'ol/Observable';
import GeoJSON from 'ol/format/GeoJSON';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
} from 'ol/style';


const highlightFeature = (json_feature) => {
  const feature = Feature.prototype.isPrototypeOf(json_feature) ? json_feature : new Feature(json_feature);
  feature.setId(json_feature.properties.ogcFid);
  feature.setGeometry(
    new GeoJSON().readGeometry(json_feature)
  );
  const dest = 'EPSG:3857'
  const src = 'EPSG:2154'
  feature.getGeometry().transform(src, dest)
  switch(json_feature.type){
    case 'Polygon':
    case 'MultiPolygon':
    case 'LineString':
    case 'MultiLineString':
      feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: 'rgba(0, 0, 255, 1)',
            width: 10,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        })
      );
      break;
    case 'Point':
    case 'MultiPoint':
      feature.setStyle(
        new Style({
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: 'rgba(0, 0, 255, 1)',
            }),
            stroke: new Stroke({
              color: 'rgba(0, 0, 255, 1)',
              width: 1,
            }),
          }),
        })
      );
      break;
    default:
      break;
  }
  return feature;
}

const highlightFeatures = (features, map) => {
  if(map){
    const featureInfoLayer = map.getLayers().getArray().find((l) => l.get('name') === 'featureInfo');
    if (featureInfoLayer){
      featureInfoLayer.getSource().clear();
      featureInfoLayer.getSource().addFeatures(features.map((json_feature) => highlightFeature(json_feature)));
    }
  }
}

const OnClick = () => {
  const map = useContext(MapContext);
  const { layers, getLayerByUuid } = useContext(LayerConfigs);
  const { currentForecast } = useContext(ForecastContext)
  const [mapEventKey, setMapEventKey] = useState();
  const [content, setContent] = useState([]);

  const unifyFeatures = (featuresByLayer) => {
    const unifiedFesturesByLayer = []
    featuresByLayer.forEach(({ features, layerName }) => {
      const unify = {};
      features.forEach(feature => {
        if (!unify[feature.properties.ogcFid]) {
          unify[feature.properties.ogcFid] = feature;
        } else {
          unify[feature.properties.ogcFid] = {
            ...unify[feature.properties.ogcFid],
            ...feature,
          }
        }
      });
      unifiedFesturesByLayer.push({
        layerName,
        features: Object.values(unify),
      })
    })
    return unifiedFesturesByLayer;
  }

  useEffect(() => {
    // Add the features to the layer having the id featureInfo
    const features = content.map((c) => c.features).flat()

    highlightFeatures(features);
    
  }, [content])

  useEffect(() => {
    // Draw the content geometries on the map

    if (map) {
      const features = content.map((c) => c.features).flat().map((json_feature) => {
        const feature = new Feature(json_feature);
        feature.setId(json_feature.properties.ogcFid);
        feature.setGeometry(
          new GeoJSON().readGeometry(json_feature)
        );
        const src = 'EPSG:4326'
        const dest = 'EPSG:3857'
        feature.getGeometry().transform(src, dest)
        switch(json_feature.type){
          case 'Polygon':
          case 'MultiPolygon':
          case 'LineString':
          case 'MultiLineString':
            feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: 'rgba(0, 0, 255, 1)',
                  width: 10,
                }),
                fill: new Fill({
                  color: 'rgba(0, 0, 255, 0.5)',
                }),
                text: new Text({
                  text: json_feature.properties.publicValues[0].value,
                  font: '12px Calibri,sans-serif',
                  fill: new Fill({
                    color: '#000',
                  }),
                  stroke: new Stroke({
                    color: '#fff',
                    width: 3,
                  }),
                  offsetY: -20,
                }),
                // text: createTextStyle(feature, resolution, myDom.polygons),
              }))
              break;
          case 'Point':
          case 'MultiPoint':
            feature.setStyle(
              new Style({
                image: new CircleStyle({
                  radius: 15,
                  fill: new Fill({
                    color: 'rgba(0, 0, 255, 0.5)',
                  }),
                  stroke: new Stroke({
                    color: 'rgba(0, 0, 255, 1)',
                    width: 1,
                  }),
                }),
                text: new Text({
                  text: json_feature.properties.publicValues[0].value,
                  font: '12px Calibri,sans-serif',
                  fill: new Fill({
                    color: '#000',
                  }),
                  stroke: new Stroke({
                    color: '#fff',
                    width: 3,
                  }),
                  offsetY: -20,
                  placement: 'line'
                }),
                // text: createTextStyle(feature, resolution, myDom.polygons),
              }))
              break;
          default:
            console.warn('Unknown geometry type', json_feature.type)
            break;
        }

        return feature;
      })
      const layer = map.getLayers().getArray().find((l) => l.get('name') === 'featureInfo');
      if (layer) {
        layer.getSource().clear();
        layer.getSource().addFeatures(features);
      }
    }

    // Remove the content geometries from the map
    const removeContent = () => {
      const layer = map.getLayers().getArray().find((l) => l.get('name') === 'featureInfo');
      if (layer) {
        layer.getSource().clear();
      }
    }
    return removeContent;
  }, [content])

  const searchFeatures = useMemo(() => (evt) => {
    setContent([])
    const features = [];
    const queries = [];
    const { pixel } = evt;
    const viewResolution = /** @type {number} */ map
      .getView()
      .getResolution();
    map.forEachLayerAtPixel(pixel,
      function (layer) {
        // Get query config
        const source = layer.getSource();
        if (!source.getFeatureInfoUrl) {
          return;
        }

        // Query data
        queries.push(
          new Promise((resolve, reject) => {
            const url = source.getFeatureInfoUrl(
              evt.coordinate,
              viewResolution,
              'EPSG:3857',
              {
                INFO_FORMAT: 'application/json',
                FORMAT: 'application/json',
              },
            );
            return Axios.get(
              url
            ).then(({ data }) => {
              if (!data?.features.length) {
                return resolve();
              }
              const featureIds = data.features.map(f => f.properties.ogc_fid)
              return Axios.get(`/layers/${layer.get('uuid')}/features_by_ids.json`,
                { params: { featureIds, forecastBulletinId: currentForecast.id } })
                .then(({ data: featuresData }) => {
                  const layerConfig = getLayerByUuid(layer);
                  features.push({
                    layerName: layerConfig.name,
                    features: featuresData.map(feature => ({
                      ...feature,
                      layerId: layerConfig.id
                    })),
                  });

                })
                .then(() => setContent(unifyFeatures(features)))
            },
            )
          }),
        );
      },
      {
        layerFilter: function (layer) {
          return layers.some((l) => (l.uuid === layer.get('uuid') && l.type === 'dynamic'));
        },
        hitTolerance: 10
      }
    );
    return Promise.all(queries)
      .then(() => setContent(unifyFeatures(features)))
  }, [currentForecast, layers])

  useEffect(() => {
    if (mapEventKey) { unByKey(mapEventKey); }
    setMapEventKey(map.on('singleclick', searchFeatures));
  }, [searchFeatures])

  const features = content.map(({ features }) => features).flat()

  const contextMenuFeatures = content.map(({ features }) => features).flat()

  return features.length
    ? <>
      <SidebarFeatureInfo content={content} />
      <FeatureHighlight features={features} />
      {contextMenuFeatures.map(feature => <FeatureContextMenu key={`feature-${feature?.properties?.ogcFid}`} feature={feature} />)}
    </>
    : null;
};

export default OnClick;
export { highlightFeature }
