import React, { useEffect, useState } from 'react';
import OSM, { ATTRIBUTION } from 'ol/source/OSM';
import PropTypes from 'prop-types';
import TileLayer from '../layers/tile';

const OSMLayer = ({ url, ...other }) => {
  const [source, setSource] = useState();
  useEffect(() => {
    setSource(new OSM({
      url,
      attributions: ATTRIBUTION,
    }));
  }, []);

  return <TileLayer source={source} {...other} />;
};

OSMLayer.propTypes = {
  url: PropTypes.string,
};

OSMLayer.defaultProps = {
  url: undefined,
};

export default OSMLayer;
