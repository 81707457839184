import React, { useRef, useEffect, useState, useContext } from "react";
import $ from "jquery";
import "bootstrap-slider";
import "bootstrap-slider/dist/css/bootstrap-slider";
import { MapContext } from "../Map";
import { getMapLayer } from "../LayerStore";

const LayerControl = ({ layer, show }) => {
  const slider = useRef();
  const map = useContext(MapContext);
  const [visible, setVisible] = useState(layer.visible);
  const [mapLayer, setMapLayer] = useState(getMapLayer(map, layer));

  // Si une couche du même nom est ajoutée à la carte, mettre à jour la référence
  map.getLayers().on("add", (e) => {
    const olLayer = e.element;
    if (olLayer.get("name") === layer.name) {
      setMapLayer(olLayer);
    }
  });

  // Lorsque les couches ont été trouvées dans la carte
  // obtenir la valeur de l'opacité et la visibilite
  useEffect(() => {
    if (mapLayer) {
      $(slider.current).slider("setValue", mapLayer.get("opacity"));
      setVisible(mapLayer.get("visible"));
    }
  }, [mapLayer]);

  // répercuter le changement de visibilité dans la carte
  useEffect(() => {
    if (mapLayer) {
      mapLayer.setVisible(visible);
    }
  }, [visible, mapLayer]);

  // Initialisation du slider
  useEffect(() => {
    if (slider.current) {
      $(slider.current)
        .slider()
        .on("change", (e) => {
          e.preventDefault();
          const target = $(e.currentTarget);
          if (mapLayer) {
            mapLayer.setOpacity(target.slider("getValue"));
          }
        });
    }
  }, [slider, mapLayer]);

  const toggleVisibility = (e) => {
    e.preventDefault();
    setVisible(!visible);
  };

  function iconNameToIcon(iconName) {
    if (!iconName) {
      return "";
    }

    const withoutFormat = iconName.split("-");
    const res = withoutFormat.splice(0, withoutFormat.length - 1).join("-");
    let faIcona = `${iconName.replace(/(fa-)?(.*)/, "$2")}`;
    const faIcon = `${faIcona.replace(/(.*)-(solid|regular)$/, "$1")}`;
    return `fas fa-${faIcon}`;
  }

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div className="row mr-0">
        <div className="col-8 menu-icon">
          <a href="#" onClick={toggleVisibility}>
            <i
              className={
                visible
                  ? iconNameToIcon(layer.iconName) || "far fa-eye"
                  : "far fa-eye-slash"
              }
            />
            <span>{layer.name}</span>
          </a>
        </div>

        <div className="col-3">
          <input
            id={`layer-${layer.uuid}-slider`}
            ref={slider}
            style={{ width: "100%", marginLeft: "50px" }}
            className="slider layer-opacity"
            type="text"
            data-layer-id={layer.uuid}
            data-slider-min="0"
            data-slider-max="1"
            data-slider-step="0.1"
            data-slider-value={(mapLayer?.get("opacity")) || 1}
          />
        </div>
      </div>
    </div>
  );
};

export default LayerControl;
