import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';

const BulkEdit = ({ selectedRowIds, columns, onUpdate }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [editableColumns, setEditableColumns] = useState([]);

  useEffect(() => {
    jQuery('#tableBulkEdit').modal(displayModal ? 'show' : 'hide');
    setEditableColumns([]);
  }, [displayModal]);

  const addEditableColumn = (c) => setEditableColumns([
    ...editableColumns,
    columns.find((col) => col.id === c),
  ]);
  const removeEditableColumn = (c) => setEditableColumns([
    ...editableColumns.filter((col) => col.id !== c),
  ]);

  const saveRecords = (values) => {
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    return axios.put(window.location.href, values);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#tableBulkEdit"
        onClick={() => setDisplayModal(true)}
      >
        Édition groupée
      </button>
      <Formik
        initialValues={{
          features: {
            values: editableColumns.reduce((acc, cur) => {
              acc[cur.id] = '';
              return acc;
            }, {}),
            ids: Object.keys(selectedRowIds)
              .filter((id) => selectedRowIds[id])
              .map((id) => parseInt(id, 10)),
          },
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          saveRecords(values)
            .then((res) => {
              actions.setSubmitting(false);
              setDisplayModal(false);
              onUpdate(res.data);
            });
        }}
      >
        {({ values }) => (
          <Form>
            <div className="modal fade" id="tableBulkEdit" tabIndex="-1" role="dialog" aria-labelledby="tableBulkEditLabel">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="tableBulkEditLabel">{`Édition groupée de ${Object.keys(selectedRowIds).length} entrées`}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <select className="form-control" onChange={(e) => addEditableColumn(e.target.value)}>
                      {columns
                        .filter((c) => editableColumns.indexOf(c) < 0)
                        .map((c) => (
                          <option key={`editable-${c.id}`} value={c.id}>{c.Header}</option>
                        ))}
                    </select>
                    <div>
                      {Object.keys(values.features.values).map((v) => (
                        <div key={`col-${v}`} className="form-group">
                          <label htmlFor={v}>{v}</label>
                          <Field name={`features.values.${v}`} className="form-control" />
                          <button
                            onClick={() => removeEditableColumn(v)}
                            className="btn btn-sm form-text text-muted"
                          >
                            Ne pas éditer
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">Enregistrer</button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

BulkEdit.propTypes = {
};

export default BulkEdit;
