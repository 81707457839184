import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from '../layers/image';
import { ForecastContext } from '../../Forecasts'

const WMSLayer = ({
  url,
  attributions,
  format,
  version,
  tiled,
  layers,
  projection,
  type,
  ...other
}) => {
  const { currentForecast, disabledIntervals, showFunctional } = useContext(ForecastContext)
  const [source, setSource] = useState();
  useEffect(() => {
    if (currentForecast) {
      const sqlViewparams = currentForecast?.id
      ? [`forecast_bulletin_id:${currentForecast.id}`]
      : [];
      if (disabledIntervals.length) {
        sqlViewparams.push(`disabled_intervals:${JSON.stringify(disabledIntervals).replace(/,/g, '\\,')}`)
      }

      sqlViewparams.push(`show_functional:${showFunctional.toString()}`)
      const viewparams = sqlViewparams.join(';')
      setSource(new ImageWMS({
        url,
        params: {
          FORMAT: format,
          VERSION: version,
          tiled,
          forecastVersion: currentForecast.evaluationsCompletedAt,
          cacheMaxDate: type === 'dynamic' && currentForecast.evaluationsCompletedAt ? currentForecast.evaluationsCompletedAt : undefined,
          LAYERS: layers,
          viewparams,
          projection,
          exceptions: 'application/vnd.ogc.se_inimage',
        },
      }));
    }
  }, [currentForecast, disabledIntervals, showFunctional]);
  return <ImageLayer source={source} {...other} isWMS zIndex={type === 'static' ? 100 : 1000} />;
};

WMSLayer.propTypes = {
  url: PropTypes.string.isRequired,
  attributions: PropTypes.string.isRequired,
};

export default WMSLayer;
