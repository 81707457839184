import React, { useCallback, useEffect, useState } from "react";
import { useForm, useFieldArray, FieldErrorsImpl } from "react-hook-form";
import { transform } from 'ol/proj';
import axios from "axios";
import { Toast } from '../Toast';
import Map from "../../types/Map";
import { Layer } from "../../types/Layer";
import User from "../../types/User";
import { OpenLayersMap } from "../ol/mapContext";
import OSM from "../ol/sources/osm";


// type plus concis pour utiliser les erreurs
type MapErrors = Partial<FieldErrorsImpl<Map>>

// Map avec les champs layers_attributes et users_attributes
interface FormMap {
  id: number,
  name: string,
  longitude: number,
  latitude: number,
  defaultZoom: number,
  layers_attributes: { _id: number, name: string }[];
  users_attributes: { _id: number, username: string }[];
};

// Un underscore est ajouté pour ne pas clasher avec l'ID de useform
const layerToFieldData = (l: Layer): { _id: number, name: string } => {
  return { _id: l.id, name: l.name }
}

const userToFieldData = (u: User): { _id: number, username: string } => {
  return { _id: u.id, username: u.username }
}


const MapsForm = ({ mapId }) => {
  const csrfToken = document.querySelector<any>("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  axios.defaults.headers.common["Key-Inflection"] = 'camel';

  const [map, setMap] = useState<FormMap>({} as FormMap);
  const [mapErrors, setMapErrors] = useState<MapErrors>();
  const [errors, setErrors] = useState<any>({});
  const [layers, setLayers] = useState<Layer[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [osmLayer, setOsmLayer] = useState<Layer>(null);

  const { register, control, handleSubmit, reset, watch, formState: { errors: formErrors }, setValue } =
    useForm({ defaultValues: {} as FormMap });

  const watchLatitude = watch('latitude', map.latitude)
  const watchLongitude = watch('longitude', map.longitude)
  const watchDefaultZoom = watch('defaultZoom', map.defaultZoom)

  useEffect(() => {
    if (mapId) {
      axios
        .get<Map>(`/maps/${mapId}.json`)
        .then(({ data: {
          id, name, layers, users, latitude, longitude, defaultZoom
        } }) => {
          setMap({
            id,
            name,
            latitude,
            longitude,
            defaultZoom,
            layers_attributes: layers.map(layerToFieldData),
            users_attributes: users.map(userToFieldData),
          });

        });
    } else {
      setMap({} as FormMap);
    }
  }, [mapId]);

  useEffect(() => {
    reset(map);
  }, [map, layers, users, reset])

  const {
    fields: userFields,
    append: appendUser,
    remove: removeUser,
    update: updateUser,
  } = useFieldArray({
    control,
    name: "users_attributes",
  });

  const {
    fields: layerFields,
    append: appendLayer,
    remove: removeLayer,
    update: updateLayer,
  } = useFieldArray({
    control,
    name: "layers_attributes",
  });

  useEffect(() => {
    axios
      .get('/layers.json')
      .then((res) => {
        setLayers(res.data.filter((layer) => layer.id).sort((a, b) => a.name.localeCompare(b.name)));
      });
  }, []);

  useEffect(() => {
    setOsmLayer(layers.find((l) => l.name === "OSM"));
  }, [layers]);

  useEffect(() => {
    axios
      .get('/users.json')
      .then((res) => {
        setUsers(res.data.filter((user) => user.id));
      });
  }, []);

  // Filter out every layers that have already been selected, except one layer that's for the current field
  const filterSelectedLayers = useCallback((exceptName: string) => {
    const selected = layerFields.map((lf) => lf.name);
    return layers.filter((l) => {
      if (l.name === exceptName)
        return true;

      return !selected.includes(l.name);
    });
  }, [layerFields, layers]);

  const filterSelectedUsers = (exceptId: number) => {
    const selected = userFields.map((uf) => uf.username);
    return users.filter((u) => {
      if (u.id === exceptId)
        return true;
      return !selected.includes(u.username);

    });
  };

  const handleAddLayerType = () => {
    appendLayer({ _id: undefined, name: "" });
  };

  const handleAddUserType = () => {
    appendUser({ _id: undefined, username: "" });
  };

  const save = (map) => {
    if (Object.keys(formErrors).length > 0) {
      setErrors({});
    }

    const curatedMap = {
      name: map.name || "",
      longitude: map.longitude || 0,
      latitude: map.latitude || 0,
      default_zoom: map.defaultZoom || 7,
      layer_ids: map.layers_attributes.map((layer) => layer._id),
      user_ids: map.users_attributes.map((user) => user._id),
    };

    const request = mapId ? axios.put : axios.post;
    const url = mapId ? `/maps/${mapId}.json` : "/maps.json";
    request(url, { map: curatedMap })
      .then(response => {
        //setMap(response.data);
        Toast.success({ title: 'Enregistré' });
        setTimeout(() => window.location.href = `/maps`, 2000);
      })
      .catch(error => {
        if (error.response.status === 422) {
          setErrors(error.response.data);
        } else {
          Toast.error({ title: error.response.statusText });
        }
      });
  };

  if (mapId && !map.id) {
    return (
      <React.StrictMode>
        <div className="card">
          <div className="card-header">
            <h1>
              {mapId
                ? `Édition de ${map.name}`
                : "Nouvelle carte"}
            </h1>
          </div>
          <p></p>
          <div className="card-body">
            <form onSubmit={handleSubmit(save)}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden"> </span>
              </div>
            </form>
          </div>
        </div>
      </React.StrictMode>
    )
  }

  /**
   * Handles the end of the map move event
   * @param e the event
   */
  const handleMapMoveEnd = (e) => {
    const center = e.map.getView().getCenter();
    const originalProjection = e.map.getView().getProjection()
    const dstProjection = 'EPSG:4326'
    const centerInWGS84 = transform(center, originalProjection, dstProjection)
    centerInWGS84[0] = Math.round(centerInWGS84[0] * 10000) / 10000;
    centerInWGS84[1] = Math.round(centerInWGS84[1] * 10000) / 10000;
    const newZoom = Math.round((e.map.getView().getZoom() + 2) * 10) / 10 /* +2 because of the size of the map in the form */
    if (newZoom !== watchDefaultZoom) {
      setValue('defaultZoom', newZoom)
    }
    if (centerInWGS84[0] !== watchLongitude) {
      setValue('longitude', centerInWGS84[0]);
    }
    if (centerInWGS84[1] !== watchLatitude) {
      setValue('latitude', centerInWGS84[1]);
    }
  }

  /**
   * The map center editor
   */
  const mapCenterEditor = map && osmLayer
    ? <OpenLayersMap
      name='edit'
      zoom={watchDefaultZoom - 2 /* -2 because of the size of the map in the form */}
      longitude={watchLongitude}
      latitude={watchLatitude}
      onMoveEnd={handleMapMoveEnd}
    >
      <OSM key={osmLayer.id} url={osmLayer.layerUrl} name={osmLayer.name} />
    </OpenLayersMap>
    : null;


  return (
    <React.StrictMode>
      <div className="card">
        <div className="card-header">
          <h1>
            {mapId
              ? `Édition de ${map.name}`
              : "Nouvelle carte"}
          </h1>
        </div>
        <p></p>

        <div className="card-body">
          <form onSubmit={handleSubmit(save)}>


            <div className="row">
              <div className="col-sm-6">

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label" htmlFor="name">
                    Nom
                  </label>
                  <div className="col-sm-8">
                    <input
                      {...register('name', { required: true })}
                      id="name"
                      className={`form-control${errors.name ? "is-invalid" : ""}`}
                      defaultValue={map?.name}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label" htmlFor="name">
                    Latitude
                  </label>
                  <div className="col-sm-3 interval-form">
                    <input
                      type="number"
                      step="0.0001"
                      id="latitude"
                      {...register('latitude', { required: true })}
                      className={`form-control ${errors.latitude}`}
                      defaultValue={""}
                    />
                  </div>
                  <small id="projectionHelp" className="form-text text-muted">
                    La projection utilisée est EPSG:4326 (WGS84)
                  </small>
                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label" htmlFor="name">
                    Longitude
                  </label>
                  <div className="col-sm-3 interval-form">
                    <input
                      type="number"
                      step="0.0001"
                      id="longitude"
                      {...register(`longitude`, { required: true })}
                      className={`form-control ${errors.longitude}`}
                      defaultValue={""}
                    />
                  </div>

                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label" htmlFor="name">
                    Zoom
                  </label>
                  <div className="col-sm-3 interval-form">
                    <input
                      type="number"
                      step="0.1"
                      id="defaultZoom"
                      {...register(`defaultZoom`, { required: true })}
                      className={`form-control ${errors.defaultZoom}`}
                      defaultValue={map.defaultZoom}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div id="map-edit" style={{
                  border: "1px solid #CCC",
                  height: "100%",
                  width: "100%",
                }}></div>

                {mapCenterEditor}
              </div>
            </div>

            { /*Couche cartographique*/}
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                Couches cartographiques
              </label>
              <div className="col-sm-10">
                <div className="row">
                  {layerFields.map((layer, index) => (
                    <div key={layer.id} className="col-sm-12 row my-2">
                      <select
                        className={"form-control col-sm-10"}
                        defaultValue={layer?._id ?? ""}
                        onChange={e => updateLayer(index, layerToFieldData(layers.find((layer) => layer.id.toString() === e.target.value)))}
                      >
                        {layer._id ? null : <option value="">Select a layer</option>}
                        {filterSelectedLayers(layer.name).map((_layer) => (
                          <option key={_layer.id} value={_layer.id}>
                            {_layer.name}
                          </option>
                        ))}
                      </select>
                      <button type="button" className="btn btn-danger col-sm-1 mx-2" onClick={() => removeLayer(index)}>Supprimer</button>

                    </div>
                  ))}
                  <div className="col-sm-12 row">
                    <div className="col-sm-10">
                    </div>
                    <button
                      className="btn btn-primary col-sm-1 m-2 pull-right"
                      id="add-name-button"
                      type="button"
                      onClick={handleAddLayerType}
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>


            { /* UTILISATEUR */}

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                Utilisateurs
              </label>
              <div className="col-sm-10">
                <div className="row">
                  {userFields.map((user, index) => (
                    <div className="col-sm-12 row my-2" key={user.id}>
                      <select
                        value={user._id}
                        onChange={e => updateUser(index, userToFieldData(users.find((user) => user.id.toString() === e.target.value)))}
                        className={"form-control col-sm-10"}
                      >

                        {user._id ? null : <option aria-label="Vide" value="" key="user-empty"> Selectionner un utilisateur</option>}
                        {filterSelectedUsers(user._id).map((_user) => (
                          <option value={_user.id} key={_user.id}>
                            {_user.username}
                          </option>
                        ))}
                      </select>
                      <button className="btn btn-danger col-sm-1 mx-2" type="button" onClick={() => removeUser(index)}>Supprimer</button>
                    </div>
                  ))}

                  <div className="col-sm-12 row">
                    <div className="col-sm-10">
                    </div>
                    <button
                      className="btn btn-primary col-sm-1 m-2 pull-right"
                      id="add-name-button"
                      type="button"
                      onClick={handleAddUserType}
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>


            <button type="submit" className="btn btn-success">
              {" "}
              Enregistrer
            </button>

          </form>
        </div>
      </div>

    </React.StrictMode>
  );
};

export default MapsForm;
