import React from 'react';
import Map from 'ol/Map';
import { OpenLayersMap } from './OpenLayersMap'
import { defaults as defaultControls, Attribution } from 'ol/control';
import MousePosition from 'ol/control/MousePosition';
import Scaleline from 'ol/control/ScaleLine'

/**
 * Map context
 * @type {React.Context<Map>}
 * @see https://reactjs.org/docs/context.html
 * @see https://openlayers.org/en/latest/apidoc/module-ol_Map-Map.html
 */
const MapContext = React.createContext(new Map({
  controls: defaultControls({ attribution: false })
    .extend([
      new MousePosition(),
      new Scaleline(),
      new Attribution({
        collapsible: true,
        collapsed: true,
      }),
    ]),
}));

export default OpenLayersMap;
export { MapContext, OpenLayersMap };
