import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Field, useFormikContext, useField,
} from 'formik';
import COMPARATORS from './comparators';


const Criteria = ({
  index, attributeTypes, formId, onChange
}) => {
  const { values, setFieldValue } = useFormikContext();

  const getValidComparators = () => {
    return values.criterias[index].attributeName
      ? COMPARATORS.filter((c) => (
        !c.onlyTypes
        || c.onlyTypes.indexOf(attributeTypes[values.criterias[index].attributeName]) >= 0))
      : [];
  }

  useEffect(() => {
    onChange(values)
  }, [values])

  const isNumber = attributeTypes[values.criterias[index].attributeName] === 'number';

  return (
    <div className="row" >
      <div className="col-4">
        <Field form={formId} type="hidden" name={`criterias[${index}][id]`} />
        <Field form={formId}
          name={`criterias[${index}][attributeName]`}
          className="form-control"
          as="select">
          {['', ...Object.keys(attributeTypes)].sort().map((attr) => (
            <option key={`criteria-${index}-attr-${attr}`} value={attr}>
              {attr}
            </option>
          ))}
        </Field>
      </div>
      <div className="col-4 ">
        <Field
          disabled={!values.criterias[index].attributeValue}
          name={`criterias[${index}][comparator]`}
          className="form-control"
          as="select"
          form={formId}
        >
          {getValidComparators().map((comp) => (
            <option key={`criteria-${index}-compare-${comp.value}`} value={comp.value}>
              {comp.label}
            </option>
          ))}
        </Field>
      </div>
      <div className="col-4">
        <Field
          disabled={!values.criterias[index].attributeValue}
          type={isNumber ? 'number' : 'text'}
          name={`criterias[${index}][attributeValue]`}
          className="form-control"
          step={isNumber ? 'any' : undefined}
          form={formId}
        />
      </div>
    </div>
  );
};

Criteria.propTypes = {
  index: PropTypes.number.isRequired,
  attributeTypes: PropTypes.shape().isRequired,
};

export default Criteria;
