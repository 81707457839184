import React, { useEffect, useContext, useState } from 'react';
import Tile from 'ol/layer/Tile';
import PropTypes from 'prop-types';
import { MapContext } from '../mapContext';
import { addCallbacksToLayer, restoreLayerAttributes } from '../../LayerStore';
const TileLayer = ({
  source, isVisible: visible, opacity, name, type, uuid, id, zIndex
}) => {
  const map = useContext(MapContext);
  const [layerSource, setLayerSource] = useState();

  const addSource = () => {
    const TileLayer = new Tile({
      id,
      source,
      zIndex,
      visible,
      opacity,
      name,
      uuid,
      type,
    });
    restoreLayerAttributes(TileLayer)
    addCallbacksToLayer(TileLayer)
    setLayerSource(TileLayer);
  };
  useEffect(() => {
    addSource();
  }, [source]);

  useEffect(() => {
    if (layerSource) {
      map.addLayer(layerSource);

    }

    return () => {
      if (layerSource) {
        map.removeLayer(layerSource);
      }
    }
  }, [layerSource])

  return null;
};

TileLayer.propTypes = {
  visible: PropTypes.bool,
  opacity: PropTypes.number,
  name: PropTypes.string.isRequired,
};

TileLayer.defaultProps = {
  visible: true,
  opacity: 1,
};

export default TileLayer;
