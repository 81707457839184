import React from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray, Field } from 'formik';


const TableGrouping = ({
  attributeTypes,
  onChange: onChangeFilters,
}) => (
    <Formik
      initialValues={{ groups: [''] }}
      onSubmit={(values, { setSubmitting }) => {
        onChangeFilters([...values.groups]);
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit, values: { groups },
      }) => (
          <div className="card">
            <div className="card-header"><h4>Regroupements</h4></div>
            <div className="card-body">
              <form onSubmit={handleSubmit} className="my-3 clearfix">

                <FieldArray
                  name="groups"
                  render={(arrayHelpers) => {
                    const addGroup = () => arrayHelpers.push('');
                    const options = [
                      '',
                      ...Object.keys(attributeTypes)]
                      .sort()
                      .map((attr) => (
                        <option key={`group-attr-${attr}`} value={attr}>
                          {attr}
                        </option>
                      ));
                    return (
                      <div>
                        {groups && groups.length > 0 ? (
                          <>
                            {groups.map((g, index) => (
                              <div className="row" key={`group-${index}`}>
                                <div className="col-11">
                                  <Field>
                                    {({ field: { onChange: onFieldChange } }) => (
                                      <select
                                        name={`groups[${index}]`}
                                        className="form-control"
                                        onChange={onFieldChange}
                                      >
                                        {options}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                                <div className="col-1 m-auto text-center">
                                  <i
                                    type="button"
                                    aria-label="Supprimer regroupement"
                                    className="fas fa-trash-alt btn btn-danger mx-2"
                                    onClick={() => arrayHelpers.remove(index)}
                                    onKeyPress={() => arrayHelpers.remove(index)}
                                    role="button"
                                    tabIndex={0}
                                  />
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                        {groups && groups.length > 0
                          ? (
                            <>
                              <div className="row mt-2">
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-success float-right"
                                    onClick={addGroup}
                                  >
                                    Ajouter un regroupement
                            <i className="fas fa-plus-circle ml-2" />
                                  </button>

                                  <button type="submit" className="btn btn-primary">
                                    Regrouper
                          </button>
                                </div>
                              </div>
                            </>
                          )
                          : null
                        }
                      </div>
                    );
                  }}
                />
              </form>
            </div>
          </div>
        )}
    </Formik>
  );

TableGrouping.propTypes = {
  attributeTypes: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TableGrouping;
