import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';

const Relationummary = ({
  authenticityToken,
  layer,
  groupByAttribute,
  attributeFilters,
}) => {
  const [relationsSummary, setRelationsSummary] = useState();
  const [linkableFeatures, setLinkableFeatures] = useState([]);
  const [stations, setStations] = useState([]);
  const [summaryLoadedAt, setSummaryLoadedAt] = useState();

  useEffect(() => {
    if (groupByAttribute) {
      Axios.get(`/layers/${layer.id}/relations.json`, {
        params: {
          group_by: groupByAttribute,
          conditions: attributeFilters,
        },
      })
        .then((res) => setRelationsSummary(res.data));
    }
  }, [groupByAttribute, summaryLoadedAt, attributeFilters]);

  useEffect(() => {
    if (relationsSummary) {
      const features = Object.values(relationsSummary).flat();
      setLinkableFeatures(features
        .reduce((prev, val) => {
          const featureIndex = prev.findIndex((f) => f.id === val.id);

          if (featureIndex < 0) {
            const { id, name = '' } = val;
            prev.push({ id, name });
          }
          return prev;
        }, [])
        .sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [relationsSummary]);

  useEffect(() => {
    Axios.get('/gauging_stations.json')
      .then((res) => setStations(res.data));
  }, []);


  const saveAssociation = (attrValue) => {
    const stationId = document.getElementById(`station_for_${attrValue}`)
      .selectedOptions[0]
      .value;
    Axios.put(`/layers/${layer.id}/associations.json`, {
      authenticity_token: authenticityToken,
      association: {
        abc: 123,
        where: {
          [groupByAttribute]: attrValue,
        },
        gauging_station_id: stationId,
      },
    })
      .then(() => setSummaryLoadedAt(new Date()));
  };

  if (!relationsSummary) { return null; }

  return (
    <table className="table table-stripped table-hover table-sm">
      <thead className="thead-dark">
        <tr>
          <th>{groupByAttribute}</th>
          {linkableFeatures.map((f) => (
            <th key={`linked_feature_${f.id}`}>
              {f.name || 'Orphelins'}
            </th>
          ))}
          <th>Station</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(relationsSummary)
          .sort()
          .map((attrValue) => (
            <tr key={`row-${attrValue}`}>
              <td>{attrValue}</td>
              {linkableFeatures.map((station) => {
                const linked = relationsSummary[attrValue].find((l) => l.id === station.id);
                return (
                  <td
                    key={`linked-${attrValue}-${station.id}`}
                    className={!station.id && linked ? 'table-danger' : undefined}
                  >
                    {linked ? linked.total : '-'}
                  </td>
                );
              })}
              <td>
                <select id={`station_for_${attrValue}`} name={`attributes[${attrValue}]`} className="form-control">
                  <option value={null} key={`option-${attrValue}-empty`}>&nbsp;</option>
                  {stations.map((s) => <option value={s.id} key={`option-${attrValue}-${s.id}`}>{s.name}</option>)}
                </select>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => saveAssociation(attrValue)}
                >
                  Enregistrer
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

Relationummary.propTypes = {
  layer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  groupByAttribute: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default Relationummary;
