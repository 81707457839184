import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import XYZ from 'ol/source/XYZ';
import TileLayer from '../layers/tile';

const XYZLayer = ({ url, attributions, ...other }) => {
  const [source, setSource] = useState();
  useEffect(() => {
    setSource(new XYZ({
      url,
      attributions,
    }));
  }, []);

  return <TileLayer source={source} {...other} />;
};

XYZLayer.propTypes = {
  url: PropTypes.string.isRequired,
  attributions: PropTypes.string.isRequired,
};

export default XYZLayer;
