import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
// import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FeatureVariable } from "../../types/FeatureVariable";
import { layerFeatureEvaluator } from "../../types/LayerFeatureEvaluator";
import DependencyForm from "../FeatureDependencyConfig/Form";
import { IConfiguratorStoreForecastableModel } from "./ConfiguratorStoreType";


interface Props {
  frameId: string;
  featureVariable: FeatureVariable,
  forecastableModel: IConfiguratorStoreForecastableModel;
}

const FeatureVariableFrame = (props: Props) => {

  // const dispatch = useAppDispatch();
  const [showAddDependencyMenu, setShowAddDependencyMenu] = useState<boolean>(false);

  const handleDeleteFeatureVariable = (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    axios.delete(`/layers/${props.featureVariable.layerId}/variables/${props.featureVariable.id}.json`)
  }


  const addDependnecyPopOver = (
    <Popover id="forecastableModelPopOver">
      <Popover.Title as="h3">Dépendance</Popover.Title>
      <Popover.Content>
        <p>Ajouter une dépendance</p>
      </Popover.Content>
    </Popover>
  )

  const deleteFeatureVariablePopOver = (
    <Popover id="forecastableModelPopOver">
      <Popover.Title as="h3">Variable</Popover.Title>
      <Popover.Content>
        <p>
          Supprimer la variable.
          Attention, supprimer cette variable peut avoir des conséquences sur les autres prévisions.
        </p>
      </Popover.Content>
    </Popover>
  )

  return (
    <div className="row justify-content-center">
      <div className="col-md-auto bg-warning justify-content-center rounded-left" id={props.frameId}>
        {props.featureVariable.name}
        <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={deleteFeatureVariablePopOver}>
          <button
            className="badge badge-pill badge-danger m-2"
            onClick={e => handleDeleteFeatureVariable(e)}
          >
            ✕
          </button>
        </OverlayTrigger>
        <div className='row pt-0 pb-0 justify-content-end'>
          <div className="col col-md-auto justify-content-end align-middle">
            <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={addDependnecyPopOver}>
              <button
                className="badge badge-pill badge-success m-2"
                onClick={_e => setShowAddDependencyMenu(true)}
              >
                +
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>

      <Modal show={showAddDependencyMenu} onHide={() => setShowAddDependencyMenu(false)} size={"xl"} >
        <DependencyForm closeModal={() => setShowAddDependencyMenu(false)} featureVariable={props.featureVariable} forecastableModel={props.forecastableModel}  />
      </Modal>


    </div>
  )
};


export default FeatureVariableFrame;
