import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import Axios from 'axios';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import FeatureSearch from '../../Table/Search';
import FeatureTable from '../../Table';
import { Toast } from '../../Toast';

const FeatureValueMapping = ({
  layerId,
  featureDependencyConfigId: configId,
  featureValueDependencyMappingId: valueMappingId,
}) => {
  const [valueMapping, setValueMapping] = useState();
  const [config, setConfig] = useState();
  const [dependencyDefinition, setDependencyDefinition] = useState({});
  const [ransackValues, setRansackValues] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [absoluteValueMode, setAbsoluteValueMode] = useState(false);

  const toRansack = (criterias = []) => criterias
    .reduce((acc, v) => {
      if (v.attribute && v.comparator && v.value) {
        let { value } = v;
        if (/match/.test(v.comparator)) {
          value = `%${v.value}%`;
        }
        acc[`${v.attribute}_${v.comparator}`] = value;
      }
      return acc;
    }, {});

  const redirectToList = () => {
    window.location.href = `/layers/${layerId}/dependencies/${configId}/value_mappings`;
  };

  const getValueMapping = () => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}/value_mappings`;
    Axios.get(valueMappingId
      ? `${endpoint}/${valueMappingId}.json`
      : `${endpoint}.json`)
      .then((res) => setValueMapping(res.data));
  };
  const getConfig = () => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}.json`;
    Axios.get(endpoint)
      .then((res) => setConfig(res.data));
  };
  const getDependencyDefinition = () => {
    if (!config || !config.dependency || !config.dependency.id) { return; }
    const endpoint = `/layers/${config.dependency.id}/features/definition.json`;
    Axios.get(endpoint)
      .then((res) => setDependencyDefinition(res.data));
  };

  useEffect(() => {
    getConfig();
  }, [layerId, configId]);

  useEffect(() => {
    getValueMapping();
  }, [layerId, configId, valueMappingId]);

  useEffect(() => {
    getDependencyDefinition();
  }, [config]);

  const createValueMapping = (params) => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}/value_mappings.json`;
    return Axios.post(endpoint, params)
      .then((res) => setValueMapping(res.data));
  };

  const updateValueMapping = (params) => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}/value_mappings/${valueMappingId}.json`;
    return Axios.put(endpoint, params);
  };

  const saveValueMapping = ({
    dependencyId,
    featureAttribute,
    absoluteValue,
    dependencyAttribute,
  }, { setFieldError }) => {
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    Axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    const params = {
      feature_value_dependency_mapping: {
        feature_ransack: ransackValues,
        dependency_id: dependencyId,
        feature_attribute: featureAttribute,
        absolute_value: absoluteValue,
        dependency_attribute: dependencyAttribute,
      },
    };
    const query = valueMappingId ? updateValueMapping(params) : createValueMapping(params);
    return query
      .then(() => {
        Toast.success({ title: <p>Relation enregistrée</p> })
          .then(() => Toast.info({ title: <p>Application en cours ...</p> }));
      })
      .catch((e) => {
        Toast.error({ title: <p>Erreur</p> });

        if (e.response && e.response.status === 422 && typeof e.response.data === 'object') {
          const errors = e.response.data;
          Object.keys(errors).forEach((attr) => (
            setFieldError(attr, errors[attr])
          ));
        }
      });
  };

  if (!config) { return null; }
  return (
    <>
      <h3>{`Relation ${config.layer.name} -> ${config.dependency.name}`}</h3>

      <div className="card">
        <div className="card-header"><h4>{config.layer.name}</h4></div>
        <div className="card-body">
          <FeatureSearch
            layerId={layerId}
            ransackValues={valueMapping ? valueMapping.feature_ransack : undefined}
            onChange={setRansackValues}
            onSubmit={() => setShowSearchResult(true)}
          />
        </div>
      </div>
      <Formik
        initialValues={{
          dependencyId: valueMapping
            ? valueMapping.dependency_id
            : '',
          absoluteValue: valueMapping
            ? valueMapping.absolute_value || ''
            : '',
          dependencyAttribute: valueMapping
            ? valueMapping.dependency_attribute || ''
            : '',
        }}
        onSubmit={saveValueMapping}
        enableReinitialize
      >
        {({ touched, errors }) => (
          <FormikForm>
            <div className="card">
              <div className="card-header"><h4>Seuil de dysfonctionnement</h4></div>
              <div className="card-body">
                <Form.Group>
                  <BootstrapSwitchButton
                    checked={absoluteValueMode}
                    onlabel="Valeur absolue"
                    onstyle="secondary"
                    offlabel="Selon attribut de la dépendence"
                    offstyle="primary"
                    size="sm"
                    width={300}
                    onChange={(checked) => setAbsoluteValueMode(checked)}
                  />
                </Form.Group>
                <span>
                  {absoluteValueMode
                    ? (
                      <Form.Group>
                        <Form.Label>Valeur absolue</Form.Label>
                        <Field
                          className={`form-control ${touched.absolute_value && errors.absolute_value ? 'is-invalid' : ''}`}
                          name="absoluteValue"
                          type="number"
                          step="any"
                        />
                        {touched.absolute_value && errors.absolute_value ? (
                          <div className="invalid-feedback">{errors.absolute_value}</div>
                        ) : null}
                      </Form.Group>
                    )
                    : (
                      <Form.Group>
                        <Form.Label>Attribut correspondant</Form.Label>
                        <Field
                          as="select"
                          className={`form-control ${touched.dependency_attribute && errors.dependency_attribute ? 'is-invalid' : ''}`}
                          name="dependencyAttribute"
                        >
                          <option aria-label="Vide" value="" key="dependency-empty" />
                          {Object.keys(dependencyDefinition).map((attribute) => (
                            <option value={attribute} key={`dependency-${attribute}`}>
                              {attribute}
                            </option>
                          ))}
                        </Field>

                        {touched.dependency_attribute && errors.dependency_attribute ? (
                          <div className="invalid-feedback">{errors.dependency_attribute}</div>
                        ) : null}
                      </Form.Group>
                    )}
                </span>
              </div>
            </div>
            <Button type="submit" variant="success">
              Enregistrer
            </Button>
            <Button type="button" variant="danger" onClick={redirectToList}>
              Annuler
            </Button>
          </FormikForm>
        )}
      </Formik>


      <Modal
        show={showSearchResult}
        dialogClassName="modal-90w"
        onHide={() => setShowSearchResult(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Entrées trouvées</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FeatureTable
            layerId={layerId}
            searchParams={toRansack(ransackValues)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSearchResult(false)}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FeatureValueMapping.propTypes = {
  layerId: PropTypes.number.isRequired,
  featureDependencyConfigId: PropTypes.number.isRequired,
  featureValueDependencyMappingId: PropTypes.number,
};

FeatureValueMapping.defaultProps = {
  featureValueDependencyMappingId: null,
};

export default FeatureValueMapping;
