import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Field, useFormikContext, useField,
} from 'formik';
import COMPARATORS from './comparators';


const Criteria = ({
  id, attributeTypes,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const getValue = (fieldName) => {
    let v = '';
    if (values.criterias && values.criterias[id]) {
      return values.criterias[id][fieldName] || ''
    }
  }
  const comparatorName = `criterias[${id}][comparator]`;

  const [attributeValue, setAttributeValue] = useState(getValue('attribute'));
  const [validComparators, setValidComparators] = useState([]);

  useEffect(() => {
    setValidComparators(attributeValue
      ? COMPARATORS.filter((c) => (
        !c.onlyTypes
        || c.onlyTypes.indexOf(attributeTypes[attributeValue]) >= 0))
      : []);
  }, [attributeValue]);

  useEffect(() => {
    const v = getValue('comparator')
    if (validComparators.length && validComparators.indexOf(v) < 0) {
      setFieldValue(comparatorName, validComparators[0].value);
    }
  }, [validComparators]);

  const isNumber = attributeTypes[attributeValue] === 'number';

  return (
    <div className="row">
      <div className="col-4">
        <Field type="hidden" name={`criterias[${id}][id]`} />
        <Field name={`criterias[${id}][attribute]`}>
          {({ field: { onChange: onFieldChange, ...other } }) => (
            <select
              className="form-control"
              onChange={(e) => {
                setAttributeValue(e.target.value);
                onFieldChange(e);
              }}
              {...other}
            >
              {['', ...Object.keys(attributeTypes)].sort().map((attr) => (
                <option key={`criteria-${id}-attr-${attr}`} value={attr}>
                  {attr}
                </option>
              ))}
            </select>
          )}
        </Field>
      </div>
      <div className="col-4 ">
        <Field
          disabled={!attributeValue}
          name={`criterias[${id}][comparator]`}
          className="form-control"
          as="select"
        >
          {validComparators.map((comp) => (
            <option key={`criteria-${id}-compare-${comp.value}`} value={comp.value}>
              {comp.label}
            </option>
          ))}
        </Field>
      </div>
      <div className="col-4">
        <Field
          disabled={!attributeValue}
          type={isNumber ? 'number' : 'text'}
          name={`criterias[${id}][value]`}
          className="form-control"
          step={isNumber ? 'any' : undefined}
        />
      </div>
    </div>
  );
};

Criteria.propTypes = {
  id: PropTypes.number.isRequired,
  attributeTypes: PropTypes.shape().isRequired,
};

export default Criteria;
