/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import $ from 'jquery'
import I18n from 'i18n-js/index.js.erb'
import 'core-js/stable';
import 'regenerator-runtime/runtime';

window.I18n = I18n;
import React from 'react'
import ReactDOM from 'react-dom'

/**
 * PATCH to fix react modules not refreashing on page change handled by Rails' `turbo`
 * @see https://github.com/reactjs/react-rails/issues/1113
 */

const ReactRailsUJS = require('react_ujs');

document.addEventListener("DOMContentLoaded", () => {
  const findComponents = (childNodes, testFn, nodes = []) => {
    for (let child of childNodes) {
      if (child.childNodes.length > 0) {
        nodes = findComponents(child.childNodes, testFn, nodes)
      } else if (testFn(child)) {
        nodes = nodes.concat([child])
      }
    }

    return nodes
  }

  const mountComponents = (nodes) => {
    for (let child of nodes) {
      const className = (child).getAttribute(ReactRailsUJS.CLASS_NAME_ATTR)
      if (className) {
        // Taken from ReastRailsUJS as is.
        const constructor = ReactRailsUJS.getConstructor(className)
        const propsJson = (child).getAttribute(ReactRailsUJS.PROPS_ATTR)
        const props = propsJson && JSON.parse(propsJson)

        // Improvement:
        // Was this component already rendered? Just hydrate it with the props coming in.
        // This is currently acceptable since all our components are expected to be reset
        // on page navigation.
        const component = React.createElement(constructor, props)
        ReactDOM.render(component, child)
      }
    }
  }

  const callback = function (mutationsList, observer) {
    const start = performance.now()
    // console.log("ReactRails: Mutation callback started...", mutationsList)

    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        if (mutation.addedNodes.length > 0) {
          const mountableNodes = findComponents(mutation.addedNodes, (child) => {
            return !!(child).dataset?.reactClass
          })

          mountComponents(mountableNodes)
        }
      }
    }

    // console.log("ReactRails: Mutation callback complete.", performance.now() - start)
  };

  const observer = new MutationObserver(callback)

  // console.log("ReactRails: Start mutation observer...")
  observer.observe(document, { childList: true, subtree: true })
})

/**
 * End of PATCH to fix react modules not refreashing on page change handled by Rails' `turbo`
 */

// main.js
import axios from "axios";

// Format nested params correctly
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return jQuery.param(params);
  };

  return config;
});

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);

ReactRailsUJS.useContext(componentRequireContext);

$(document).ready(function() {
  const autoHiddenAlerts = $('.alert:not(.alert-keep)')
  autoHiddenAlerts.hide();
  autoHiddenAlerts.fadeTo(3000, 500).slideUp(500, function() {
    autoHiddenAlerts.slideUp(500);
  });
});