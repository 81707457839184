import React from 'react';
import PropTypes from 'prop-types';
import { ActionCableConsumer } from 'react-actioncable-provider';

export default class Widget extends React.Component {
  static propTypes = {
    message: PropTypes.string
  };

  constructor(...props) {
    super(...props);

    this.handleReceived = this.handleReceived.bind(this);

    this.state = {
      message: ''
    };
  }

  handleReceived(message) {
    this.setState(state => {
      return {
        message
      };
    });
  }

  render() {
    return (
      <ActionCableConsumer
        channel="FeatureDependencyMappingChannel"
        onReceived={this.handleReceived}
      >
        <h1>{this.state.message}</h1>
      </ActionCableConsumer>
    );
  }
}