export default [
  { label: 'égal à', value: '==' },
  { hidden: true, label: 'égal à au moins un', value: 'eq_any' },
  { hidden: true, label: 'égal à tous', value: 'eq_all' },
  { label: 'différent de', value: '!=' },
  { hidden: true, label: 'différent d\'au moins un', value: 'not_eq_any' },
  { hidden: true, label: 'différent de tous', value: 'not_eq_all' },
  { label: 'correspond à', value: '=~', onlyTypes: ['string'] },
  {
    hidden: true, label: 'correspond à au moins un', value: 'matches_any', onlyTypes: ['string'],
  },
  {
    hidden: true, label: 'correspond à tous', value: 'matches_all', onlyTypes: ['string'],
  },
  { label: 'ne correspond pas à', value: '!=~', onlyTypes: ['string'] },
  {
    hidden: true, label: 'ne correspond pas à au moins un', value: 'does_not_match_any', onlyTypes: ['string'],
  },
  {
    hidden: true, label: 'ne correspond à aucun', value: 'does_not_match_all', onlyTypes: ['string'],
  },
  { label: 'inférieur à', value: '<' },
  { hidden: true, label: 'inférieur à au moins un', value: 'lt_any' },
  { hidden: true, label: 'inférieur à tous', value: 'lt_all' },
  { label: 'inférieur ou égal à', value: '<=' },
  { hidden: true, label: 'inférieur ou égal à au moins un', value: 'lteq_any' },
  { hidden: true, label: 'inférieur ou égal à tous', value: 'lteq_all' },
  { label: 'supérieur à', value: '>' },
  { hidden: true, label: 'supérieur à au moins un', value: 'gt_any' },
  { hidden: true, label: 'supérieur à tous', value: 'gt_all' },
  { label: 'supérieur ou égal à', value: '>=' },
  { hidden: true, label: 'supérieur ou égal à au moins un', value: 'gteq_any' },
  { hidden: true, label: 'supérieur ou égal à tous', value: 'gteq_all' },
  { label: 'inclus dans', value: 'in' },
  { hidden: true, label: 'inclus dans au moins un', value: 'in_any' },
  { hidden: true, label: 'inclus dans tous', value: 'in_all' },
  { label: 'non inclus dans', value: 'not_in' },
  { hidden: true, label: 'non inclus dans au moins un', value: 'not_in_any' },
  { hidden: true, label: 'non inclus dans tous', value: 'not_in_all' },
  { label: 'contient', value: 'include', onlyTypes: ['string'] },
  {
    hidden: true, label: 'contient au moins un', value: 'cont_any', onlyTypes: ['string'],
  },
  { hidden: true, label: 'contient tous', value: 'cont_all' },
  { label: 'ne contient pas', value: 'not_include', onlyTypes: ['string'] },
  {
    hidden: true, label: 'ne contient pas au moins un', value: 'not_cont_any', onlyTypes: ['string'],
  },
  {
    hidden: true, label: 'ne contient pas tous', value: 'not_cont_all', onlyTypes: ['string'],
  },
  { label: 'commence par', value: 'start_with', onlyTypes: ['string'] },
  {
    hidden: true, label: 'commence par au moins un', value: 'start_any', onlyTypes: ['string'],
  },
  {
    hidden: true, label: 'commence par tous', value: 'start_all', onlyTypes: ['string'],
  },
  { label: 'ne commence pas par', value: 'not_start_with', onlyTypes: ['string'] },
  {
    hidden: true, label: 'ne commence pas par au moins un', value: 'not_start_any', onlyTypes: ['string'],
  },
  {
    hidden: true, label: 'ne commence pas par tous', value: 'not_start_all', onlyTypes: ['string'],
  },
  { label: 'finit par', value: 'end_with', onlyTypes: ['string'] },
  {
    hidden: true, label: 'finit par au moins un', value: 'end_any', onlyTypes: ['string'],
  },
  {
    hidden: true, label: 'finit par tous', value: 'end_all', onlyTypes: ['string'],
  },
  { label: 'ne finit pas par', value: 'not_end_with', onlyTypes: ['string'] },
  {
    hidden: true, label: 'ne finit pas par au moins un', value: 'not_end_any', onlyTypes: ['string'],
  },
  {
    hidden: true, label: 'ne finit pas par tous', value: 'not_end_all', onlyTypes: ['string'],
  },
  { label: 'est vrai', value: 'true', onlyTypes: ['boolean'] },
  { label: 'est faux', value: 'false', onlyTypes: ['boolean'] },
  { label: 'est présent', value: 'present', onlyTypes: ['string'] },
  { label: 'est vide', value: 'blank', onlyTypes: ['string'] },
  { label: 'est nul', value: 'null' },
  { label: 'n\'est pas nul', value: 'not_null' },
].filter((c) => !c.hidden);
