import React, { useContext } from 'react';
import './InformationStrip.css'
import { ForecastContext } from '../Forecasts';
import ForecastSelector from './ForecastSelector';
import ForecastSourceDisplay from './ForecastSourceDisplay';

// confugurable to show the reference station
// chose between a select right in the component or conf page
// chose prevision depending on the latest bulletin

/**
 * This component is used to display the information strip at the top of the map page.
 * It includes:
 * - the current displayed forecast bulletin and its alternative
 * - the possibility to select another forecast date and/or alternative
 * - the possibility to select the forecast values to display
 *
 * @returns {React.Component} The information strip in top of the map
 */
const InformationStrip = () => {
  const { currentForecast } = useContext(ForecastContext)

  return <div className="information-strip-container">
    {currentForecast?.id ?
      (
        <>
          <ForecastSelector />
          <ForecastSourceDisplay />
        </>
      ) :
      (<span>Aucune prévision n'a pu être chargée.</span>)
    }
  </div>
}

export default InformationStrip