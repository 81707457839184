import React, { useState, useEffect } from "react";
import { useFormikContext, FieldArray } from "formik";
import { Field } from "formik";
import { FieldWithError } from "../../Form/Error";
import Select from "react-select";
import axios from 'axios'
const COMPARATORS = ["==", "!=", "<=", "<", ">", ">="];

const FeatureMapsList = () => {
  const [ options, setOptions ] = useState([])
  const { values, errors } = useFormikContext();



  useEffect(() => {
    if (values.layerId) {
      axios
        .get(`/layers/${values.layerId}/features/definition.json`)
        .then(({ data }) => {
          setOptions(Object.keys(data).map((attributeName) => (
            { label: attributeName, value: attributeName, ...data[attributeName] }
          )));
        });
    } else {
      setOptions([]);
    }
  }, [values.layerId]);


  return (
    <FieldArray
      name="featureMapsAttributes"
      render={(arrayHelpers) => (
        <table className="table table-striped">
          <thead className={errors.featureMaps ? "bg-danger" : "thead-dark"}>
            <tr>
              <th>Attribut</th>
              <th>Comparateur</th>
              <th>Valeur d'attribut</th>
              {errors.featureMaps
              ? null
              : <th></th>}
            </tr>
          </thead>

          <tbody>
            {errors.featureMaps ? (
              <tr>
                <td colSpan={8}>
                  <ul>
                    {(errors.featureMaps || []).map((err) => (
                      <li
                        className="text-danger"
                        key={`global-error-effects-${err}`}
                      >
                        {err}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            ) : (
              values.featureMapsAttributes.map((mapping, mappingIndex) => (
                <tr key={`feature-map-${mapping.id || `new-${mappingIndex}`}`}>
                  <td>
                    <Field
                      type="hidden"
                      name={`featureMapsAttributes[${mappingIndex}].id`}
                    />
                    <FieldWithError
                      as="select"
                      disabled={!values.layerId}
                      placeholder="Nom d'attribut"
                      className={`form-control`}
                      name={`featureMapsAttributes[${mappingIndex}].attributeName`}
                      errorName={`featureMaps[${mappingIndex}].attributeName`}
                    >{options
                      .map(option => (
                          <option key={`feature-attribute-${option.value}`} 
                            value={options.value}>{option.label}</option>
                      ))}
                    </FieldWithError>
                  </td>
                  <td>
                    <FieldWithError
                      className={`form-control`}
                      as="select"
                      name={`featureMapsAttributes[${mappingIndex}].comparator`}
                      errorName={`featureMaps[${mappingIndex}].comparator`}
                    >
                      <option disabled value="">
                        Comparateur
                      </option>
                      {COMPARATORS.map((comp) => (
                        <option key={`comparator-${comp}`} value={comp}>
                          {comp}
                        </option>
                      ))}
                    </FieldWithError>
                  </td>
                  <td>
                    <FieldWithError
                      errorName={`featureMaps[${mappingIndex}].attributeValue`}
                      placeholder="Valeur de l'attribut"
                      className={`form-control`}
                      name={`featureMapsAttributes[${mappingIndex}].attributeValue`}
                    />
                  </td>
                  <td>
                    <a onClick={() => arrayHelpers.remove(mappingIndex)}>
                      <i className="fas fa-trash text-danger"></i>
                    </a>
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8} className="text-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => arrayHelpers.push({})}
                >
                  Ajouter un critère
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      )}
    />
  );
};

export default FeatureMapsList;
