import React, { useState } from "react"
import I18n from "i18n-js"
import ForecastBulletinEvent from "../../../types/ForecastBulletinEvent";
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { TimelineElementColor } from "../TimeLineElement";

type Props = {
  event: ForecastBulletinEvent;
  formattedValue: (value: any) => string;
}

const FeatureResultOverrideEventContent = ({ event, formattedValue }: Props) => {
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false)

  const toggleVisibility = () => setDetailsVisible(!detailsVisible)
  const bgcolor = TimelineElementColor(event)
  const templateTextColor = '#c8c9ce'
  let subtitle;
  return (

    <VerticalTimelineElement
      className="timeline-element-component"
      contentStyle={{ background: bgcolor, backgroundOpacity: 0.5, color: templateTextColor, boxShadow: `0 3px ${event.iconColor}` }}
      contentArrowStyle={{ borderLeft: '7px solid', borderLeftColor: event.iconColor }}
      date={<span className="pull-right">{formattedValue(event.occuredAt)} ({event.createdBy.name})</span>}
      iconStyle={{ background: bgcolor, color: event.iconColor, padding: '12px', margin: '12px' }}
      icon={<i className={event.icon} />}
      key={event.title}
    >
      <h5 className="vertical-timeline-element-title" >
        <a href={event.eventable.url} target="_blank" rel="noopener noreferrer" className="timeline-element-link">
          {event.eventable.name}
        </a>
        {/*I18n.t(event.eventableType)*/}
        <small className="text-muted pull-right clickable" onClick={toggleVisibility}>
          <i className={`fas fa-${detailsVisible ? 'minus' : 'plus'}`} />
        </small>
      </h5>
      <h6 style={{ color: '#8b939b' }} className="vertical-timeline-element-subtitle">
        {I18n.t(`timeline.forecast_bulletin.${event.action}`)}
      </h6>
      <div style={{ display: detailsVisible ? '' : 'none' }} className="mt-2">
        <div className="row">
          <div className="col-md-3 font-weight-bold">
            Modèle
          </div>
          <div className="col-md-9">
            {event.details.forecastableModel}
          </div>
          <div className="col-md-3 font-weight-bold">
            Publié
          </div>
          <div className="col-md-9">
            {formattedValue(event.eventable.issuedAt)}
          </div>

          {event.details.resultRegistering && (
            <>
            <div className="col-md-3 font-weight-bold">
              Résultat
            </div>
            <div className="col-md-9">
              <a href={event.details.resultRegistering.url} target="_blank" rel="noopener noreferrer" className="timeline-element-link">
                {formattedValue(event.details.resultRegistering.status)}
              </a>
            </div>
              <div className="col-md-3 font-weight-bold">
                Démarré
              </div>
              <div className="col-md-9">
                {formattedValue(event.details.resultRegistering.startedAt)}
              </div>
              <div className="col-md-3 font-weight-bold">
                Terminé
              </div>
              <div className="col-md-9">
                {formattedValue(event.details.resultRegistering.completedAt)}
              </div>
              <div className="col-md-3 font-weight-bold">
                Durée
              </div>
              <div className="col-md-9">
                {formattedValue(event.details.resultRegistering.elapsedTime)}
              </div>
            </>
          )}
        </div>
      </div>
    </VerticalTimelineElement>
  )
}

export default FeatureResultOverrideEventContent