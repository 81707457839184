/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const HeaderCell = ({ column }) => {
  let sortIcon = null;

  if (column.isSorted) {
    sortIcon = column.isSortedDesc
      ? <i className="pl-1 fas fa-sort-down" />
      : <i className="pl-1 fas fa-sort-up" />;
  }

  return (
  // Add the sorting props to control sorting. For this example
  // we can add them into the header props
    <th
      {...column.getHeaderProps()}
      style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
      className={column.isGrouped ? 'bg-secondary' : undefined}
    >
      <span {...column.getSortByToggleProps({
        title: `Trier par ${column.render('Header')}`,
      })}
      >
        {column.render('Header')}
        {sortIcon}
      </span>
    </th>
  );
};

export default HeaderCell;
