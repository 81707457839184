import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FeatureSearchForm from './Form'
import { Modal, Button } from 'react-bootstrap';

const FeatureSearch = ({modal, show, onCloseModal, ...other}) => {
  const [ isShown, setIsShown ] = useState(show)

  const closeModal = () => {
    if(onCloseModal){ onCloseModal() }
    setIsShown(false)
  }
  return modal
  ? <Modal
      show={isShown}
      dialogClassName="modal-90w"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Recherche d'objets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FeatureSearchForm {...other} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  : <FeatureSearchForm {...other} />
};

FeatureSearch.propTypes = {
  modal: PropTypes.bool,
  show: PropTypes.bool,
};

FeatureSearch.defaultProps = {
  modal: false,
  show: true,
}

export default FeatureSearch;
