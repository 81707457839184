/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useTable, usePagination, useSortBy, useRowSelect,
} from 'react-table';
import TablePagination from '../../Table/Pagination';
import HeaderCell from '../../Table/HeaderCell';
import TableColumnFilter from '../../Table/HiddenColumns';
import { useEffect } from 'react';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool.isRequired
}

// https://github.com/tannerlinsley/react-table/blob/master/examples/pagination-controlled/src/App.js
// Let's add a onFetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function Table({
  result,
  attributeTypes,
  loading,
  gotoPage: propsGotoPage,
  setPageSize: propsSetPageSize,
  setSortBy: propsSetSortBy,
  onSearchUpdate,
}) {

  const columns = useMemo(() => attributeTypes
    ? Object.keys(attributeTypes).map(def => ({ accessor: def, Header: def }))
    : [], [result])

  const data = useMemo(() => result.data.map(feature => feature.properties) || [], [result]);

  const {
    getTableProps,
    getTableBodyProps,
    getToggleHideAllColumnsProps,
    headerGroups,
    prepareRow,
    allColumns,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex, pageSize, sortBy,
      selectedRowIds,
      ...otherStates
    },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      pageCount: result.paginate ? result.paginate.totalPages : 0,
      useControlledState: (state) => React.useMemo(
        () => ({
          ...state,
        }),
        [state],
      ),
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    onSearchUpdate({
      pagination: {
        per_page: pageSize,
        page: pageIndex,
      },
      sort_by: sortBy,
    })
  }, [pageIndex, pageSize, sortBy])

  return (
    <>
      <TableColumnFilter
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        columns={allColumns}
      />
      <div className="row justify-content-end">
        <button onClick={() => setDisplayColumnsFilter(true)} type="button" className="btn float-right mt-2" data-toggle="modal" data-target="#columnSelector">
          Colonnes
            <i className="fas fa-columns ml-2"></i>
        </button>
      </div>
      <table
        {...getTableProps()}
        className="table table-striped table-sm table-hover table-responsive"
      >
        <thead className="thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <HeaderCell column={column} key={`header-${column.render('Header')}`} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ opacity: loading ? 0.5 : 1 }}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan="10000">Loading...</td>
            ) : (
                <td colSpan="10000">
                  Affichage de
                  {' '}
                  {page.length}
                  {' '}
                  resultats sur
                  {' '}
                  {result.paginate.totalCount}
                </td>
              )}
          </tr>
        </tfoot>
      </table>

      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        previousPage={previousPage}
        nextPage={nextPage}
        gotoPage={gotoPage}
        pageSize={pageSize}
        pageIndex={ pageIndex}
        pageOptions={pageOptions}
        setPageSize={setPageSize}
      />
    </>
  );
}

Table.propTypes = {
  editable: PropTypes.bool,
  tableView: PropTypes.shape({
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    sortBy: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool,
    })),
  }),
};

Table.defaultProps = {
  tableView: {
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    updatedAt: null,
  },
  editable: false,
};

export default Table;
