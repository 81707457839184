import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import Axios from 'axios';
import FeatureSearch from '../../Table/Search';
import FeatureTable from '../../Table';
import { Toast } from '../../Toast';

const FeatureMapping = ({
  layerId,
  featureDependencyConfigId: configId,
  featureDependencyMappingId: mappingId,
}) => {
  const [mapping, setMapping] = useState();
  const [config, setConfig] = useState();
  const [dependencies, setDependencies] = useState([]);
  const [ransackValues, setRansackValues] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);

  const toRansack = (criterias = []) => criterias
    .reduce((acc, v) => {
      if (v.attribute && v.comparator && v.value) {
        let { value } = v;
        if (/match/.test(v.comparator)) {
          value = `%${v.value}%`;
        }
        acc[`${v.attribute}_${v.comparator}`] = value;
      }
      return acc;
    }, {});

  const redirectToList = () => {
    window.location.href = `/layers/${layerId}/dependencies/${configId}/mappings`;
  };

  const getMapping = () => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}/mappings`;
    axios.get(mappingId
      ? `${endpoint}/${mappingId}.json`
      : `${endpoint}.json`)
      .then((res) => setMapping(res.data));
  };
  const getConfig = () => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}.json`;
    axios.get(endpoint)
      .then((res) => setConfig(res.data));
  };
  const getDependencies = () => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}/dependency_features.json`;
    axios.get(endpoint)
      .then((res) => setDependencies(res.data));
  };

  useEffect(() => {
    getConfig();
  }, [layerId, configId]);

  useEffect(() => {
    getMapping();
  }, [layerId, configId, mappingId]);

  useEffect(() => {
    getDependencies();
  }, [layerId, configId]);

  const createMapping = (params) => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}/mappings.json`;
    return Axios.post(endpoint, params)
      .then((res) => setMapping(res.data));
  };

  const updateMapping = (params) => {
    const endpoint = `/layers/${layerId}/dependencies/${configId}/mappings/${mappingId}.json`;
    return Axios.put(endpoint, params);
  };

  const saveMapping = ({ dependency_id }, { setFieldError }) => {
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    Axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    const params = {
      feature_dependency_mapping: {
        ransack_params: ransackValues,
        dependency_id,
      },
    };
    const query = mappingId ? updateMapping(params) : createMapping(params);
    return query
      .then(() => {
        Toast.success({ title: <p>Relation enregistrée</p> })
          .then(() => Toast.info({ title: <p>Application en cours ...</p> }));
      })
      .catch((e) => {
        Toast.error({ title: <p>Erreur</p> });

        if (e.response && e.response.status === 422 && typeof e.response.data === 'object') {
          const errors = e.response.data;
          Object.keys(errors).forEach((attr) => (
            setFieldError(attr, errors[attr])
          ));
        }
      });
  };

  if (!config) { return null; }
  return (
    <>
      <h3>{`Relation ${config.layer.name} -> ${config.dependency.name}`}</h3>

      <div className="card">
        <div className="card-header"><h4>{config.layer.name}</h4></div>
        <div className="card-body">
          <FeatureSearch
            layerId={layerId}
            ransackValues={mapping ? mapping.ransack_params : undefined}
            onChange={setRansackValues}
            onSubmit={() => setShowSearchResult(true)}
          />
        </div>
      </div>
      <Formik
        initialValues={{
          dependency_id: mapping
            ? mapping.dependency_id
            : '',
        }}
        onSubmit={saveMapping}
        enableReinitialize
      >
        {({ touched, errors }) => (
          <Form>


            <div className="card">
              <div className="card-header"><h4>{config.dependency.name}</h4></div>
              <div className="card-body">
                <Field
                  as="select"
                  className={`form-control ${touched.dependency_id && errors.dependency_id ? 'is-invalid' : ''}`}
                  name="dependency_id"
                >
                  <option aria-label="Vide" value="" key="dependency-empty" />
                  {dependencies.map((dependency) => (
                    <option value={dependency.ogc_fid} key={`dependency-${dependency.ogc_fid}`}>
                      {dependency.nom_site}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            {touched.dependency_id && errors.dependency_id ? (
              <div className="invalid-feedback">{errors.dependency_id}</div>
            ) : null}
            <Button type="submit" variant="success">
              Enregistrer
            </Button>
            <Button type="button" variant="danger" onClick={redirectToList}>
              Annuler
            </Button>
          </Form>
        )}
      </Formik>


      <Modal
        show={showSearchResult}
        dialogClassName="modal-90w"
        onHide={() => setShowSearchResult(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Entrées trouvées</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FeatureTable
            layerId={layerId}
            searchParams={toRansack(ransackValues)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSearchResult(false)}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeatureMapping;
