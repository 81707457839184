import axios from 'axios';
import React, { useState, useEffect, useMemo } from 'react';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { Layer } from '../../types/Layer';
import FieldWithApiErrors from '../ReactHookForms/FieldWithApiErrors';
import I18n from "i18n-js"
import { Toast } from '../Toast';
import Switch from 'react-bootstrap/esm/Switch';

interface LayersFormParams {
  layerId?: string;
}

interface FormLayer extends Omit<Layer, "id" | "isImported"> {
  _id: number;
  isImported: string;
}

const LayersForm = ({ layerId }: LayersFormParams) => {

  const [layer, setLayer] = useState<FormLayer>({} as FormLayer);
  const [errors, setErrors] = useState<any>({});
  const [layerTypes, setLayerTypes] = useState<{ _id: number, name: string }[]>([]);
  const [layerSources, setLayerSources] = useState<{ _id: number, name: string }[]>([]);


  // Go back to main page
  const backToList = () => {
    window.location.href = "/layers";
  };

  /**
   * Handle form submit errors 
   * @param {*} errors
   */
  const handleHookFormsErrors = (data) => {
    const deepCopyErrors = JSON.parse(JSON.stringify(errors));

    Object.keys(data).forEach((key) => {

      if (deepCopyErrors[key]) {
        deepCopyErrors[key].push(data[key]);
      } else {
        deepCopyErrors[key] = [data[key]];
      }

    });
  };

  /**
   * Save Layer to server
   * @param {*} req
   */
  const save = (req: FormLayer) => {
    const formData = new FormData();
    formData.append("layer[is_imported]", req.isImported)
    formData.append("layer[locale]", req.locale)
    formData.append("layer[name]", req.name)
    formData.append("layer[layer_source_id]", req.layerSourceId?.toString())
    formData.append("layer[layer_type_id]", req.layerTypeId?.toString());
    formData.append("layer[attributions]", req.attributions);
    formData.append("layer[opacity_by_default]", req.opacityByDefault?.toString());
    formData.append("layer[z_index]", req.zIndex?.toString());
    formData.append("layer[projection]", req.projection);
    formData.append("layer[shapefile]", req.shapefile[0]);



    const [url, method] = layerId
      ? [`/layers/${layerId}.json`, "patch"]
      : [`/layers.json`, "post"];


    axios[method](url, formData, { "Content-Type": "multipart/form-data" })
      .then(({ data }) => {
        setLayer(data);
        setErrors([]);
      })
      .then(() => Toast.success({ title: "Enregistré" }))
      .then(backToList)
      .catch((res) => {
        Toast.error({
          title:
            res.response.status === 422
              ? "Données invalides"
              : res.response.statusText,
        });
        if (res.response.status === 422) {
          setErrors(res.response.data);
        }
      });
  };

  useEffect(() => {
    if (layerId) {
      axios.get(`/layers/${layerId}.json`).then((response) => {
        setLayer(response.data);
      });
    }
  }, [layerId]);

  useEffect(() => {
    axios.get(`/layer_types.json`).then((response) => {
      setLayerTypes(response.data.map(layerType => {
        return { ...layerType, _id: layerType.id }
      }
      ))
    });
  }, []);

  useEffect(() => {
    axios.get(`/layer_sources.json`).then((response) => {
      setLayerSources(response.data);
    });
  }, []);

  const { register, handleSubmit, control, reset, setValue, getValues, watch, formState: { errors: formErrors, ...otherFormState } } =
    useForm({
      defaultValues: layer
    });


  const watchImported = watch("isImported", layer.isImported);

  return (
    <div className="row">
      <form onSubmit={handleSubmit(save, handleHookFormsErrors)}>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              Général
            </div>
            <div className="card-body">



              <div className="form-group row">
                <label className="col-sm-2 form-label" htmlFor="is_imported">
                  Importer...
                </label>
                <div className="col-sm-10">
                  <div className="form-check">

                    <div className='row'>
                      <input className="form-check-input"
                        type="radio"
                        name="layerDataSource"
                        id="isImported"
                        value="true"
                        {...register("isImported", { required: true })}
                      />

                      <label className="form-check-label" htmlFor="isImported">
                        ...depuis un fichier shape

                      </label>
                    </div>

                    <div className='row'>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="layerDataSource"
                        id="isNotImported"
                        value="false"
                        {...register("isImported", { required: true })}
                      />
                      <label className="form-check-label" htmlFor="isNotImported">
                        ...depuis une couche externe
                      </label>
                    </div>

                  </div>

                  {errors["isImported"] && (
                    <div className="invalid-feedback">{
                      errors["isImported"].length > 1 ?
                        errors["isImported"].map((error: any) => <div key={error}>{error}</div>) :
                        errors["isImported"]
                    }</div>
                  )}



                </div>
              </div>


              {/* <FieldWithApiErrors
                name="is_imported"
                type="checkbox"
                defaultValue={true}
                label="Importer depuis un fichier shape"
                errors={errors}
                registerCallBack={register}
              /> */}

              <FieldWithApiErrors
                name="name"
                defaultValue={layer.name}
                label="Nom"
                errors={errors}
                registerCallBack={register}
              />
              {
                watchImported === "true" &&
                <>
                  <FieldWithApiErrors
                    name="shapefile"
                    // defaultValue={layer.shapefile}
                    type="file"
                    label="Fichier shape"
                    errors={errors}
                    registerCallBack={register}
                  />

                  <FieldWithApiErrors
                    name="projection"
                    defaultValue={layer.projection}
                    label="Projection"
                    placeholder='Déduit automatiquement depuis le fichier shape'
                    errors={errors}
                    registerCallBack={register}
                    required={false}
                  />
                </>
              }
              {
                watchImported === "false" &&
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label" htmlFor={"layer-source"}>
                    Type de source
                  </label>
                  <div className="col-sm-10">
                    <select
                      className={`form-control  ${errors["layerSourceId"] ? "is-invalid" : ""}`}
                      id={"layer-source"}
                      defaultValue={layer?.layerSourceId}
                      {...register('layerSourceId', { required: true })}
                    >
                      <option value="">Choisir une source pour la couche</option>
                      {layerSources.map((layerSource: any) => (
                        <option key={layerSource._id} value={layerSource._id}>
                          {I18n.t(`layer_sources.${layerSource.name}`)}
                        </option>
                      ))}
                    </select>
                    {errors["layerSourceId"] && (
                      <div className="invalid-feedback">{errors["layerSourceId"]}</div>
                    )}
                  </div>
                </div>


              }

              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor={"layer-type"}>
                  Type de couche
                </label>
                <div className="col-sm-10">
                  <select
                    className={`form-control  ${errors["layerTypeId"] ? "is-invalid" : ""}`}
                    id={"layer-type"}
                    defaultValue={layer?.layerTypeId}
                    {...register('layerTypeId', { required: true })}
                  >
                    <option value="">Choisir un type de couche</option>
                    {layerTypes.map((layerType: any) => (
                      <option key={layerType._id} value={`${layerType._id}`}>
                        {I18n.t(`layer_types.${layerType.name}`)}
                      </option>
                    ))}
                  </select>
                  {errors["layerTypeId"] && (
                    <div className="invalid-feedback">{errors["layerTypeId"]}</div>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              Affichage
            </div>
            <div className="card-body">

              <FieldWithApiErrors
                type='number'
                name="zIndex"
                defaultValue={layer.zIndex}
                label="Priorité d'affichage"
                errors={errors}
                registerCallBack={register}
              />

              <FieldWithApiErrors
                type='textarea'
                name="attributions"
                defaultValue={layer.attributions}
                label="Crédits"
                errors={errors}
                registerCallBack={register}
              />

              <FieldWithApiErrors
                type='number'
                name="opacityByDefault"
                defaultValue={layer.opacityByDefault}
                label="Opacité par défaut"
                errors={errors}
                registerCallBack={register}
              />

              <div className="col-8 p-4 text-right">

              </div>

            </div>
            <button type="submit" className="btn btn-success">
              {" "}
              Enregistrer
            </button>
          </div>
        </div>

      </form>
    </div>
  );
}

export default LayersForm;