/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import ReactPaginate from 'react-paginate';

const TablePagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  previousPage,
  nextPage,
  gotoPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
}) => (<>
  <div className="row">
    <div className="col">
      <ReactPaginate
        previousLabel={'Préc.'}
        nextLabel={'Suiv.'}
        breakLabel={'...'}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        forcePage={pageIndex}
        disableInitialCallback
        onPageChange={({ selected }) => gotoPage(selected)}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        containerClassName="pagination"
      /></div>
    <div className="col form-inline mb-4">
      <label>
        Page:
        <input
          className="form-control"
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const pageNumber = e.target.value ? Number(e.target.value) : 1;
            gotoPage(pageNumber);
          }}
          style={{ width: '100px', display: 'inline' }}
        />
      </label>
    </div>
    <div className="col">
      <select
        value={pageSize}
        className="form-control"
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((allowedPageSize) => (
          <option key={allowedPageSize} value={allowedPageSize}>
            {allowedPageSize}
            {' '}
            par page
          </option>
        ))}
      </select>
    </div>
  </div>
</>
);

export default TablePagination;
