import React, { useEffect, useState } from 'react';
import { get as getProjection } from 'ol/proj.js';
import { getWidth } from 'ol/extent.js';
import PropTypes from 'prop-types';
import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import TileLayer from '../layers/tile';

const WMSLayer = ({
  url,
  attributions,
  format,
  version,
  tiled,
  layers,
  projection,
  ...other
}) => {
  const resolutions = [];
  const matrixIds = [];
  const proj = getProjection(projection);
  const maxResolution = getWidth(proj.getExtent()) / 256;

  for (let i = 0; i < 18; i++) {
    matrixIds[i] = i.toString();
    resolutions[i] = maxResolution / Math.pow(2, i);
  }

  const tileGrid = new WMTSTileGrid({
    origin: [-20037508, 20037508],
    resolutions,
    matrixIds,
  });

  const [source, setSource] = useState();
  useEffect(() => {
    setSource(new WMTS({
      url,
      layer: layers,
      matrixSet: 'PM',
      format: 'image/jpeg',
      projection,
      tileGrid,
      attributions,
      style: 'normal',
      crossOrigin: 'anonymous',
    }));
  }, []);

  return <TileLayer source={source} {...other} isWMS />;
};

WMSLayer.propTypes = {
  url: PropTypes.string.isRequired,
  attributions: PropTypes.string.isRequired,
};

export default WMSLayer;
