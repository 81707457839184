import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { Toast } from '../../../Toast';
import COMPARATORS from './comparators';
import VariableSelect from '../../Variables/Select';
import Select from '../../../Form/Select'

// Donc voici le composant d'édition d'un requirement d'un type de données
// Lorsqu'on lance les containers web (docker-compose.yml)
// Les fichiers jsx sont automatiquement transpilés en javascript
// À chaque modification d'un fichier javascript :
// 1. La transpilation est relancée automatiquement
// 2. Rails prévient la page web par websocket qu'elle doit se rafraîchir
const FeatureConfigForm = ({ layerId, dependencyConfigId, id } /* props passées par la vue */) => {
  if (!layerId || !dependencyConfigId) { throw new Error('Missing paramemeters'); }

  // Nécessaire pour les formulaires Rails
  // Pour éviter certains types d'injection et le double-envoi de formulaire
  // Idéalement on mettrait ça dans un module js qu'on intègrerait au besoin
  // Pour l'instant je confesse que je copie/colle ce bout de code !
  const csrfToken = document.querySelector('[name=csrf-token]').content;
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  // Les states nécessaires
  // Dans tes tutos, est-ce que c'était avec le Hooks ?
  // oui mais j'ai vu les hooks separement parce que le tuto sur le site react est pas a jour et j'ai du voir ca sur stackoverflow
  // ok.
  const [requirement, setRequirement] = useState();
  const [featureAttributes, setFeatureAttributes] = useState({});
  const [dependencyConfig, setDependencyConfig] = useState();
  const [initialErrors, setInitialErrors] = useState({});
  const [dependencyVariable, setDependencyVariable] = useState()

  // Là à chaque fois que le id change, on récupère les données complètes:
  // (note: les URL terminent par .json pour que Rails sache que les données doivent être envoyées en JSON)
  useEffect(() => {
    axios
      .get(`/layers/${layerId}/dependencies/${dependencyConfigId}/requirements/${id || 'new'}.json`)
      .then((res) => {
        const req = res.data;
        Object.keys(req).forEach((attr) => {
          req[attr] = req[attr] || '';
        });
        // Qu'on stocke dans le state "requirement"
        setRequirement(req);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`/layers/${layerId}/dependencies/${dependencyConfigId}.json`)
      .then((res) => setDependencyConfig(res.data));
  }, [dependencyConfigId]);

  useEffect(() => {
    axios
      .get(`/layers/${layerId}/features/definition.json`)
      .then((res) => {
        setFeatureAttributes(res.data);
      });
  }, [layerId]);

  const backToList = () => {
    window.location = `/layers/${layerId}/dependencies/${dependencyConfigId}/requirements`;
  };

  const save = (req) => {
    const [url, method] = req.id
      ? [`/layers/${layerId}/dependencies/${dependencyConfigId}/requirements/${req.id}.json`, 'put']
      : [`/layers/${layerId}/dependencies/${dependencyConfigId}/requirements.json`, 'post'];

    axios[method](url, { requirement: req })
      .then(() => Toast.success({ title: 'Enregistré' }))
      .then(backToList)
      .catch((res) => {
        Toast.error({
          title: res.response.status === 422
            ? 'Données invalides'
            : res.response.statusText,
        });
        if (res.response.status === 422) {
          setInitialErrors(res.response.data);
        }
      });
  };

  if (!dependencyConfig || !requirement) {
    return null;
  }

  const variableIsBoolean = dependencyVariable && dependencyVariable.variableType === 'boolean'

  // Génération du HTML
  return ( 
    <> {/* <> est une balise React vide. React impose que le rendu soit dans une balise HTML unique
        on ne peut pas faire de :
        return (
          <p>1</p>
          <p>2</p>
        )
        On doit faire :
        return (
          <div> // ou <p> ou <> etc ... 
            <p>1</p>
            <p>2</p>
          </div>
        )

        <></> est un raccourci pour <React.Fragment></React.Fragment>
     */}
      <h2 className="mb-4">
        {requirement.id
          ? `Exigence envers ${dependencyConfig.dependency.name}`
          : 'Nouvelle exigence'}
      </h2>
      { /* 
        Formik est un module pour gérer des formulaires dans React.
        C'est pas mal, mais j'évalue d'autres qui sont sortis récemment.
        Si tu en trouves un plus simple / complet, on pourra l'évaluer
      */}
      <Formik
        initialValues={requirement /* Les valeurs initiales du formulaire */ }
        onSubmit={save /* la méthode à appeler quand le formulaire est soumis */}
        enableReinitialize /* Permet de remplacer les initialValues après une requête AJAX par exemple */ 
        initialErrors={initialErrors} /* Les erreurs de validations */
      >
        {({ touched, errors }) => (
          <Form className="row">
            {/* Affichage des erreurs si il y en a 
            Certains composants, pourraient facilement être décomposés en sous-composant
            Celui-ci commence à devenir gros et ça rend le HTML moins lisible ...
            */}
            <div className="col-5">
              <div className={`card ${errors.layer && 'border-danger'}`}>
                <div className="card-header text-white bg-primary mb-3">
                  <h3>{dependencyConfig.layer.name}</h3>
                  {errors.layer && (
                  <div className="invalid-feedback">
                    {errors.layer}
                  </div>
                  )}
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="featureAttribute" className="col-12">
                      Attribut
                      <Field
                        required={!variableIsBoolean}
                        disabled={variableIsBoolean}
                        as="select"
                        id="featureAttribute"
                        className={`form-control ${touched.featureAttribute && errors.featureAttribute ? 'is-invalid' : ''}`}
                        name="featureAttribute"
                      >
                        <option disabled aria-label="Vide" value="" key="feature-attr-empty" />
                        {Object.keys(featureAttributes).sort().map((attr) => (
                          <option value={attr} key={`feature-${attr}`}>
                            {attr}
                          </option>
                        ))}
                      </Field>
                      {errors.featureAttribute && (
                      <div className="invalid-feedback">
                        {errors.featureAttribute}
                      </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2 text-center my-auto">
              <div>
                Fonctionnel si
              </div>
              <div>
                <i style={{ fontSize: '4em' }} className="fas fa-long-arrow-alt-right" />
              </div>
              <div>
                <div className="form-group">
                  <Select 
                    required={!variableIsBoolean}
                    disabled={variableIsBoolean}
                    name="comparator"
                    options={ variableIsBoolean
                        ? [{ value: '', label: ''}]
                        : Object.keys(COMPARATORS).map(c => ({ value: c, label: COMPARATORS[c]}))}
                  />
                  {errors.comparator && (
                    <div className="invalid-feedback">
                      {errors.comparator}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-5">
              <div className={`card ${errors.dependency && 'border-danger'}`}>
                <div className="form-check card-header text-white bg-warning mb-3">
                  <h3>{dependencyConfig.dependency.name}</h3>
                  {errors.dependency && (
                  <div className="invalid-feedback d-block">
                    {errors.dependency}
                  </div>
                  )}
                </div>
                <div className="card-body">
                  <div>
                    <label htmlFor="dependencyVariableId" className="col-12">
                      Variable
                      {/* Par exemple ici, j'ai créé un sous-composant */ }
                      <VariableSelect
                        layerId={dependencyConfig.dependencyId}
                        name="dependencyVariableId"
                        formik={{ touched, errors }}
                        onChange={setDependencyVariable}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 text-right">
              <Button type="submit" variant="success" className="mr-2">
                Enregistrer
              </Button>
              <Button
                type="button"
                variant="danger"
                onClick={backToList /* appelle la méthode backToList du composant (avec le HTML event en paramètre */}
                // On aurait pu écrire :
                // onClick={() => backToList()}
                // onClick={() => backToList(myCustomParam)}
              >
                Annuler
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

// Les propTypes sont un typeChecking qui lève des erreurs en mode développement
// Quand le format des props n'est pas respecté
FeatureConfigForm.propTypes = {
  layerId: PropTypes.number.isRequired, // Nombre, obligatoire
  dependencyConfigId: PropTypes.number.isRequired,
  id: PropTypes.number, // Nombre, pas obligatoire (null si nouveau Requirement)
};

// Valeur par défaut pour les props qui ne sont pas obligatoires
FeatureConfigForm.defaultProps = {
  id: null, 
};

export default FeatureConfigForm;
