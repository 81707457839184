import React from "react";

interface FieldWithApiErrorsProps {
  name: string;
  errors: any;
  registerCallBack: any;
  label: string;
  defaultValue?: string | number;
  placeholder?: string;
  type?: string;
  required?: boolean;
}


const FieldWithApiErrors = ({ name, errors, registerCallBack, label, defaultValue, placeholder = null, required = true, type = "text" }: FieldWithApiErrorsProps) => {

  return (
    <div className="form-group row">
      <label className="col-sm-2 form-label" htmlFor={name}>
        {label}
      </label>
      <div className="col-sm-10">
        <input
          type={type}
          className={`form-control   ${errors[name] ? "is-invalid" : ""}`}
          placeholder={placeholder}
          defaultValue={defaultValue}
          id={name}
          {...registerCallBack(name, { required })}
        />
        {errors[name] && (
          <div className="invalid-feedback">{
            errors[name].length > 1 ?
              errors[name].map((error: any) => <div key={error}>{error}</div>) :
              errors[name]
          }</div>
        )}
      </div>
    </div>
  )

}

export default FieldWithApiErrors;
