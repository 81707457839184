import React, { useEffect, useState } from "react";

import axios from "axios";
import { Formik, Field, Form } from "formik";
import ActionPlanEffectList from "./ActionPlanEffect/List";
import FeatureMappingList from "./FeatureMapping/List";
//import VariableForecastForm from "./VariableForecast/List";
import {Toast} from '../Toast'
import ResourceList from "./ActionPlanResource/List";
import { FieldWithError } from "../Form/Error";

const EMPTY_ACTION_PLAN = {
  actionPlanEffectsAttributes: [],
  featureMapsAttributes: [],
  variableForecastIds: [],
  actionPlanResourcesAttributes: [],
  actionPlanTypeId: "",
  name: "",
  layerId: "",
};

const ActionPlanForm = ({ actionPlanId }) => {
  const csrfToken = document.querySelector("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  axios.defaults.headers.common["Key-Inflection"] = 'camel'; 

  const [actionPlanTypes, setActionPlanTypes] = useState([]);
  const [featureTypes, setFeatureTypes] = useState([]);
  const [actionPlanEffects, setActionPlanEffects] = useState([]);
  const [actionPlan, setActionPlan] = useState(EMPTY_ACTION_PLAN);
  const [actionPlanErrors, setActionPlanErrors] = useState();

  // useEffect(() => {
  //   if(actionPlanId){
  //     axios
  //       .get(`/action_plans/${actionPlanId}.json`) // action_plan_types plutôt non ? en effet
  //       .then((res) => {
  //         setActionPlan(res.data);
  //       })
  //     }
  // }, [actionPlanId])

  useEffect(() => {
    axios
      .get("/action_plan_types.json") // action_plan_types plutôt non ? en effet
      .then((res) => {
        setActionPlanTypes(res.data);
      });
  }, []);

  useEffect(() => {
    axios.get("/layers/imported.json").then((res) => {
      setFeatureTypes(res.data);
    });
  }, []);

  useEffect(() => {
    if (actionPlanId) {
      axios
        .get(`/action_plans/${actionPlanId}.json`) // action_plan_types plutôt non ? en effet
        .then((res) => {
          setActionPlan(res.data);
        });
    } else {
      setActionPlan(EMPTY_ACTION_PLAN);
    }
  }, [actionPlanId]);

  const save = (actionPlan, { setErrors }) => {
    const query = actionPlanId
      ? axios.patch(`/action_plans/${actionPlanId}.json`, { actionPlan }) // UPDATE
      : axios.post(`/action_plans.json`, { actionPlan }); // CREATE
    return query
      .then((res) => setActionPlan(res.data))
      .then(() => Toast.success({title: 'Enregistré' } ))
      .catch(({ response }) => {
        if (response.status === 422 /* validation error code */) {
          // Quand des erreurs de validation (422) arrivent,
          // je corrige : on les enregistre dans Formik avec sa méthode `setErrors`
          // qu'on a reçu en paramètre de cette méthode (`save`)
          setErrors(response.data);
        } else {
          Toast.error({title: response.statusText  } )
        }
      });
  };

  return (
    <Formik
      initialValues={actionPlan}
      onSubmit={save}
      initialErrors={
        actionPlanErrors /* pas sùr que ce soit encore utile ça, du coup,
        puisqu'on utilise la méthode setError de Formik ...*/
      }
      enableReinitialize
    >
      {({ touched, errors, values, setFieldValue }) => (
        <Form>
          <div className="card">
            <div className="card-header">
              <h2>
                {actionPlanId
                  ? `Édition du plan d'action "${actionPlan.name}"`
                  : "Nouveau plan d'action"}
              </h2>
            </div>

            <div className="card-body">
              <div className="form-group row">
                <label className="col-form-label col-sm-3 required">Nom</label>
                <div className="col-9">
                  <FieldWithError
                    as="input"
                    // className='form-control'
                    name="name"
                    className={`form-control ${errors.name && "is-invalid"}`}
                    placeholder="Nom du plan d'action"
                  />
                </div>
              </div>
              <div className={`${errors.layer && "border-danger"}`}>
                <div className="form-group row">
                  <label className="col-form-label col-sm-3 required">
                    Type de plan d'action
                  </label>
                  <div className="col-sm-9">
                    <FieldWithError
                      as="select"
                      className={`form-control`}
                      name="actionPlanTypeId" /* Nom du champ qu'on veut définir. C'est un belongs_to */
                      errorName="actionPlanType" /* En cas d'erreur, Rails n'indique pas l'erreur 
                        sur le champs 'action_plan_type_id', mais sur l'objet 
                        'action_plan_type'. C'est pourquoi on on doit préciser ça à FieldWithError
                        pour qu'il puisse trouver l'erreur correspondante dans Formik ...
                      */

                      /* ici ça va correspondre au champ qu'on veut remplir
                    donc "oui"
                    pour key en dessous on met ce qu'on veut, tant que c'est unique dans le composant (et sous-composants)
                    Ça aide React à gérer son cache (Virtual DOM)
                    il faudrait annoncer le composant, avec un label, est ce que je peux juste reprendre
                    la facon dont c'est fait dansl 'autre Form?
                    Oui j'imagine. Au pire une fois que le formulaire marche, on améliorera le UI
                    dans ce cas autant aller a l'essentiel pour les labels pour l'instant OK
                    */
                    >
                      <option
                        disabled
                        defaultValue=""
                        key={"defaut-action-plan-type"}
                      >
                        {" "}
                      </option>
                      {actionPlanTypes.map((type) => (
                        <option
                          value={type.id}
                          key={`action-plan-type-${type.id}`}
                        >
                          {type.name}
                        </option>
                      ))}
                    </FieldWithError>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-3 required">
                    Type d'infrastructures
                  </label>
                  <div className="col-sm-9">
                    <FieldWithError
                      as="select"
                      name="layerId"
                      errorName="layer"
                      className={`form-control ${
                        errors.featureType && "is-invalid"
                      }`}
                    >
                      <option
                        disabled
                        defaultValue=""
                        key={"default-feature-type"}
                      >
                        {" "}
                      </option>
                      {featureTypes.map((type) => (
                        <option value={type.id} key={`feature-type-${type.id}`}>
                          {type.name}
                        </option>
                      ))}
                    </FieldWithError>
                  </div>
                </div>
              </div>
            </div>
          </div>
          { values.layerId && (
            <>
          <div className="card">
            <div className="card-header">
              <h3>Infrastructures concernées</h3>
            </div>
            <div className="card-body">
              <p>Seules les infrastructures remplissant tous les critères suivants pour se voir appliquer le plan d'action :</p>
              <FeatureMappingList />
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Ressources nécessaires</h3>
            </div>
            <div className="card-body">
              <p>Seules les infrastructures pouvant bénéficier des ressources suivantes pourront se voir appliquer le plan d'action :</p>
              <ResourceList />
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Effets du plan d'action</h3>
            </div>
            <div className="card-body">
              <p>Effets du plan d'action sur l'infrastructure qui se le voit appliquer :</p>
              <ActionPlanEffectList />
            </div>
          </div>
        </>)}

          <div className="row">
            <div className="col-sm-9"></div>
            <div className="col-sm-3 text-right">
              <button type="submit" className="btn btn-success">
                Enregistrer
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ActionPlanForm;
