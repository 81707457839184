/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useTable, usePagination, useGroupBy, useSortBy, useRowSelect,
} from 'react-table';
import axios from 'axios';
import TablePagination from './Pagination';
import HeaderCell from './HeaderCell';
import TableGroup from './Group';
import TableColumnFilter from './HiddenColumns';
import TableBulkEdit from './BulkEdit';
import TableSearch from './Search'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool.isRequired
}

// https://github.com/tannerlinsley/react-table/blob/master/examples/pagination-controlled/src/App.js
// Let's add a onFetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function Table({
  layerId,
  editable,
  searchParams: originalSearchParams
}) {
  const [searchParams, setSearchParams] = useState(originalSearchParams);
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(true);
  const [updatedAt, setUpdatedAt] = useState();
  const [groupBy, setGroupBy] = useState([])
  const [displaySearchForm, setDisplaySearchForm] = useState(false)
  const [displayGrouping, setDisplayGrouping] = useState(false)
  const [displayColumnsFilter, setDisplayColumnsFilter] = useState(false)

  const memoizedGroupBy = useMemo(() => groupBy, [groupBy]);

  const columns = useMemo(() => result.definition
    ? Object.keys(result.definition).map(def => ({ accessor: def, Header: def }))
    : [], [result])

  const data = useMemo(() => result.data || [], [result]);
  const {
    getTableProps,
    getTableBodyProps,
    getToggleHideAllColumnsProps,
    headerGroups,
    prepareRow,
    allColumns,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex, pageSize, sortBy,
      selectedRowIds,
      ...otherStates
    },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      manualGroupBy: true,
      pageCount: result.paginate ? result.paginate.totalPages : 0,
      groupBy: memoizedGroupBy,
      useControlledState: (state) => React.useMemo(
        () => ({
          ...state,
          groupBy,
        }),
        [state, groupBy],
      ),
      onFetchData: ({ a, b, c, d }) => {},
      /*
      initialState: {
        /* sortBy: columns.length && columns[0].accessor
          ? [{ id: columns[0].accessor, desc: false }]
          : [], */
      // groupBy, // memoGroupBy,
      /*},
      
    },*/
      /* 
   
       // Here we will use a plugin to add our selection column
      /* ,*/
    },
    useGroupBy,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!editable) { return; }
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          // Make this column a groupByBoundary. This ensures that groupBy columns
          // are placed after it
          groupByBoundary: true,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    if (layerId) {
      axios.get(`/layers/${layerId}/features.json`, {
        params: {
          page: pageIndex,
          per_page: pageSize,
          group_by: groupBy,
          q: { s: sortBy, ...searchParams },
        },
      })
        .then((res) => {
          setResult(res.data);
          setLoading(false);
        });
    }
  }, [pageIndex, pageSize, sortBy, groupBy, searchParams, updatedAt]);

  // Render the UI for your table
  if (!result.definition) { return null; }
  return (
    <>
      <TableColumnFilter
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        columns={allColumns}
        display={displaySearchForm}
      />
      {displayGrouping
        ? <TableGroup attributeTypes={result.definition} onChange={setGroupBy} />
        : null}

      {editable
        ? <TableBulkEdit
          selectedRowIds={selectedRowIds}
          columns={allColumns}
          onUpdate={() => setUpdatedAt(new Date())}
        />
        : false}
      {displaySearchForm
        ? <div className="card">
          <div className="card-header"><h4>Filtres</h4></div>
          <div className="card-body">
            <TableSearch attributeTypes={result.definition} onChange={setSearchParams} />
          </div>
        </div>
        : null
      }
      <div className="row justify-content-end">
        {displaySearchForm
          ? <button
            type="button"
            className="btn float-right mt-2"
            onClick={() => {
              setGroupBy([])
              setDisplaySearchForm(false)
            }}
          >
            Supprimer les filtres
              <i className="fas fa-filter ml-2" />
          </button>
          : <button
            type="button"
            className="btn float-right mt-2"
            onClick={() => setDisplaySearchForm(true)}
          >
            Filtrer
                <i className="fas fa-filter ml-2" />
          </button>
        }
        {displayGrouping
          ? <button
            type="button"
            className="btn float-right mt-2"
            onClick={() => {
              setSearchParams({})
              setDisplayGrouping(false)
            }}
          >
            Dégrouper
              <i className="fas fa-filter ml-2" />
          </button>
          : <button
            type="button"
            className="btn float-right mt-2"
            onClick={() => setDisplayGrouping(true)}
          >
            Regrouper
                <i className="fas fa-filter ml-2" />
          </button>
        }
        <button onClick={() => setDisplayColumnsFilter(true)} type="button" className="btn float-right mt-2" data-toggle="modal" data-target="#columnSelector">
          Colonnes
            <i className="fas fa-columns ml-2"></i>
        </button>
      </div>
      <table
        {...getTableProps()}
        className="table table-striped table-sm table-hover table-responsive"
      >
        <thead className="thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <HeaderCell column={column} key={`header-${column.render('Header')}`} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ opacity: loading ? 0.5 : 1 }}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan="10000">Loading...</td>
            ) : (
                <td colSpan="10000">
                  Affichage de
                  {' '}
                  {page.length}
                  {' '}
                  resultats sur
                  {' '}
                  {result.paginate.totalCount}
                </td>
              )}
          </tr>
        </tfoot>
      </table>

      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        previousPage={previousPage}
        nextPage={nextPage}
        gotoPage={gotoPage}
        pageSize={pageSize}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        setPageSize={setPageSize}
      />
    </>
  );
}

Table.propTypes = {
  layerId: PropTypes.number.isRequired,
  editable: PropTypes.bool,
  tableView: PropTypes.shape({
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    sortBy: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool,
    })),
    groupBy: PropTypes.arrayOf(PropTypes.string),
    updatedAt: PropTypes.instanceOf(Date),
  }),
};

Table.defaultProps = {
  tableView: {
    pageIndex: 1,
    pageSize: 10,
    sortBy: [],
    groupBy: [],
    updatedAt: null,
  },
  editable: false,
};

export default Table;
