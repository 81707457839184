import React, { useRef, useEffect, useState, useContext, useMemo } from "react";
import $ from "jquery";
import "bootstrap-slider";
import "bootstrap-slider/dist/css/bootstrap-slider";
import { MapContext } from "../Map";
import { getMapLayer } from "../LayerStore";
import axios from "axios";
import { Field, Formik } from "formik";
import { ForecastContext } from "../Forecasts";
import { Toast } from "../Toast";
import { useLayers } from "../ol/LayersContext";

const GroupedLayerControl = ({ layer, show }) => {
  const slider = useRef();
  const map = useContext(MapContext);

  const [mapLayer, setMapLayer] = useState();
  const [visible, setVisible] = useState(layer.visible);
  const [forecastedValues, setForecastedValues] = useState([]);
  const [forecastInterval, setForecastInterval] = useState(0);
  const [layerFilterGroups, setLayerFilterGroups] = useState([]);
  const [showGroupOptions, setShowGroupOptions] = useState(false);
  const [layerFilters, setLayerFilters] = useState({});
  const { currentForecast } = useContext(ForecastContext)
  const { updateLayerByOlLayer } = useLayers();


  // Lorsque les couches ont été trouvées dans la carte
  // obtenir la valeur de l'opacité et la visibilite
  useEffect(() => {
    if (mapLayer) {
      $(slider.current).slider("setValue", mapLayer.get("opacity"));
    }
    setVisible(mapLayer?.get("visible"));
  }, [mapLayer]);

  // Si une couche du même nom est ajoutée à la carte, mettre à jour la référence
  map.getLayers().on("add", (e) => {
    const olLayer = e.element;
    if (olLayer.get("name") === layer.name) {
      setMapLayer(olLayer);
    }
  });

  // répercuter le changement de visibilité dans la carte
  useEffect(() => {
    if (mapLayer) {
      mapLayer.setVisible(visible);
    }
  }, [mapLayer, visible]);

  // Initialisation du slider
  useEffect(() => {
    if (slider.current) {
      $(slider.current)
        .slider()
        .on("change", (e) => {
          e.preventDefault();
          const target = $(e.currentTarget);
          const mapLayer = getMapLayer(map, layer);
          if (mapLayer) {
            mapLayer.setOpacity(target.slider("getValue"));
          }
        });
    }
  }, [slider, mapLayer]);

  /**
   * Get the forecast bulletin for the current layer
   */
  useEffect(() => {
    if (layer && currentForecast?.id) {
      axios.get(`/layers/${layer.id}/filters.json?forecast_bulletin_id=${currentForecast.id}`).then(({ data }) => {
        setLayerFilterGroups(data.valueGroups);
      })
        .catch((err) => {
          console.error(err);
          Toast.error({ title: "Erreur", text: "Impossible de charger les options de filtre" });
        })
    }
  }, [layer, currentForecast]);

  /**
   * Toggle the visibility of the filters
   * @param {event} e The event
   */
  const toggleVisibility = (e) => {
    e.preventDefault();
    setVisible(!visible);
  };

  /**
   * Returns the font awesome icon based on its short name
   * @param {string} iconName The name of the icon to display
   * @returns The font-awesome icon class name
   */
  function iconNameToIcon(iconName) {
    if (!iconName) {
      return "";
    }

    let faIcona = `${iconName.replace(/(fa-)?(.*)/, "$2")}`;
    const faIcon = `${faIcona.replace(/(.*)-(solid|regular)$/, "$1")}`;
    return `fas fa-${faIcon}`;
  }

  const toggleShowGroupOptions = (e) => {
    setShowGroupOptions(!showGroupOptions);
    e.stopPropagation();
  };

  /**
   * Apply the filter to the layer with CQL
   */
  useEffect(() => {
    const olLayer = getMapLayer(map, layer);
    if(olLayer){
      updateLayerByOlLayer(olLayer, { layerFilters });
    }
  }, [layer, layerFilters, map]);

  /**
   *
   * @param {Object} params params to apply to the layer
   * @param {Object} params.values Values returned by the form
   * @param {Function} params.callback Function to call after the form has been updated
   * @returns
   */
  const FormikListener = ({ values, callback }) => {
    useEffect(() => {
      callback(values);
    }, [callback, values]);

    return null;
  };

  useEffect(() => {

    if (layerFilterGroups.length > 0) {
      const options = layerFilterGroups.reduce((prev, { name, options, forecasts }) => {
        const forecast = forecasts.find(f => f.interval.toString() === forecastInterval.toString())
        const stationValue = options.find(value => forecast.humanReadableValue?.match(`${value}$`))
        return { ...prev, [name]: stationValue || "" };
      }, {})
      setForecastedValues(options)
    }
    else {
      setForecastedValues([]);
    }
  }, [layerFilterGroups, forecastInterval]);

  const resetForecastedValues = () => {
    const newValues = Object.keys(forecastedValues).reduce((prev, v) => {
      prev[v] = '';
      return prev
    }, {})
    setForecastedValues(newValues);
  }

  const groupOptionsSelector = useMemo(() => {
    if (layerFilterGroups.length === 0) {
      return null;
    }

    return (
      <Formik
        initialValues={forecastedValues}
        enableReinitialize
      >
        {({ values, handleReset, resetForm, setValues, ...other }) => (
          <form className="pr-3">
            <div className="row mr-0">
              <a
                href="#"
                className="col-12 text-right text-secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  resetForecastedValues();
                }}
              >
                Supprimer les filtres
              </a>
            </div>
            <FormikListener values={values} callback={setLayerFilters} />
            {currentForecast && currentForecast.forecastableModel && (
              <div key="time-interval-selector" className="row mr-0 mb-3">
                <div className="col-9 text-secondary">Intervalle :</div>

                <Field
                  as="select"
                  className={`col-3 form-control-sm`}
                  name='timeInterval'
                  onChange={(e) => {
                    setForecastInterval(e.target.value);
                  }}
                >
                  {
                    currentForecast.forecastableModel.timeIntervals.map((interval) => (
                      <option key={`interval-${interval.from}`} value={interval.from}>T{interval.from}</option>
                    ))
                  }
                </Field>
              </div>)}
            {layerFilterGroups.map(({ name, options }) => (
              <div key={`group-${name}`} className="row mr-0">
                <div className="col-9 text-secondary">{name}</div>
                <Field
                  as="select"
                  className={`col-3 form-control-sm`}
                  name={name}
                >
                  <option aria-label="Vide" value="" key="feature-attr-empty" />
                  {options.map((option) => (
                    <option
                      value={option}
                      key={`${layer.id}-${name}-option-${option}`}
                    >
                      {option}
                    </option>
                  ))}
                </Field>
              </div>
            ))}
          </form>
        )
        }
      </Formik >
    );
  }, [layerFilterGroups, forecastedValues, currentForecast]);

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div className="row mr-0">
        <div className="col-7 menu-icon">
          <a href="#" onClick={toggleVisibility}>
            <i
              className={
                visible
                  ? iconNameToIcon(layer.iconName) || "far fa-eye"
                  : "far fa-eye-slash"
              }
            />
            <span>{layer.name}</span>
          </a>
        </div>

        <div className="col-2">
          <a href="#" onClick={toggleShowGroupOptions}>
            <i
              className="fas fa-filter"
              title={`Filtrer par ${layer.groupOptions[0]} / ${layer.groupOptions[1]}`}
            >
              {Object.values(layerFilters).filter((f) => f).length ? (
                <span className="badge bg-secondary">
                  {Object.values(layerFilters).filter((f) => f).length}
                </span>
              ) : null}
            </i>
          </a>
        </div>

        <div className="col-3">
          <input
            id={`layer-${layer.uuid}-slider`}
            ref={slider}
            style={{ width: "100%", marginLeft: "50px" }}
            className="slider layer-opacity"
            type="text"
            data-layer-id={layer.uuid}
            data-slider-min="0"
            data-slider-max="1"
            data-slider-step="0.1"
            data-slider-value={(mapLayer && mapLayer.get("opacity")) || 1}
          />
        </div>
      </div>

      <div style={{ display: showGroupOptions ? "" : "none" }}>
        {groupOptionsSelector}
      </div>

    </div>
  );
};

export default GroupedLayerControl;
