import React, { useEffect, useLayoutEffect, useState } from 'react'
import axios from "axios";
import { SearchBar } from '../SearchBar/SearchBar';

const csrfToken = document.querySelector("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;



const ReferenceFeatures = () => {
  const pageSize = 50;
  
  const [features, setFeatures] = useState([])
  const [currentPage, setCurrentPage] = useState(0);

  const [layers, setLayers] = useState([])
  const [selectedLayerID, setSelectedLayerID] = useState()

  const [filteringMethod, setFilteringMethod] = useState('layer')

  //get layers
  useEffect(() => {
    const fetchLayers = async () => {
      const { data } = await axios.get('/layers.json',)
      const validLayers = data.filter(layer => layer.layerTypeId > 1)
      setLayers(validLayers)
      setSelectedLayerID(validLayers[0].id)
    }
    
    fetchLayers();
  }, [filteringMethod])


  //get feature
  useEffect(() => {
    const fetchFeatures = async () => {
      const { data } = await axios.get(`/layers/${selectedLayerID}/features.json`, { params: { page: currentPage, pageSize } })
      setFeatures(data || [])
    }
    if (selectedLayerID) {
      fetchFeatures();
    }

  }, [currentPage, selectedLayerID])

  // search features request
  const searchFeatures = (inputValue) => {
    if (inputValue.length < 4) { return []; }
    return axios.get('/features/search', { params: { q: inputValue } })
      .then(({ data }) => data
        .map(feature => featureOption(feature, inputValue))
      )
  }

  // set isReference flag for a feature
  // function setReference(id) {
  //   axios.post(`/features/${id}.json`)
  //   .then(res => {
  //   }).catch(err => {
  //   })
  // }

  function updateCurrentPage(value) {
    const newValue = currentPage + value;
    setCurrentPage(newValue)
  }

  function renderFilterMethod() {
    switch (filteringMethod) {
      case 'search':
        return (
          <SearchBar
            loadOptions={searchFeatures}
          />
        )

      case 'layer':
        return (
          <select onChange={e => setSelectedLayerID(e.target.value)}>
            {
              layers.map((layer, i) => (<option key={i} value={layer.uuid}>{layer.name}</option>))
            }
          </select>
        )

      default:
        return (<span>Pas de methode de filtrage séléctionnée.</span>)

    }
  }

  return (
    <div className="card">
      <div className="card-header">
        <h2> Features de reference </h2>
        <div>
          Trier par:
          <form>
            <input defaultChecked type="radio" name="filter" onClick={() => setFilteringMethod('layer')} /> couche <br />
            <input type="radio" name="filter" onClick={() => setFilteringMethod('search')} /> recherche
          </form>

        </div>
        {
          renderFilterMethod()
        }

      </div>

      <div className="card-body">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th> Nom </th>
              <th>Status</th>
              <th> Feature de reference </th>
            </tr>
          </thead>
          <tbody>
            {
              features.map((feature, index) => {
                return (
                  <tr key={index}>
                    <th>
                      {
                        feature.properties.static_name
                      }
                    </th>
                    <th>
                      {
                        feature.properties.plugs_status
                      }
                    </th>
                    <th>
                      <input
                        value={feature.isReference}
                        type="checkbox"
                        checked={feature.isReference}
                        // onClick={e => setReference(feature._key)}
                      />
                    </th>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <button disabled={currentPage === 0} type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => updateCurrentPage(-1)}>Precedents</button>
        <button disabled={features.length < 50} type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => updateCurrentPage(1)}>Suivants</button>
      </div>
      <div className="card-footer">
        <button type="button" className="btn btn-primary" data-dismiss="modal">Terminé</button>
      </div>
    </div>
  )
}

export default ReferenceFeatures;
