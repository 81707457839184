import React from 'react'
import { useFormikContext, Field, FieldArray } from 'formik';
import { FieldWithError } from '../../Form/Error';
import { useEffect } from 'react';
import Axios from 'axios';
import { useState } from 'react';

const NEW_EFFECT = {
  attributeName: '',
  valueModificatorAttributes: {
    modificatorType: ''
  }
}

const ActionPlanEffectList = () => {
  const { values, errors } = useFormikContext();


  const [layer, setLayer] = useState()

  useEffect(() => {
    if (values.layerId) {
      Axios.get(`/layers/${values.layerId}.json`)
        .then(res => setLayer(res.data))
    }
  }, [values.layerId])

  if (!layer) { return null; }
  return (
    <FieldArray
      name="actionPlanEffectsAttributes"
      render={arrayHelpers => (
        <table className="table table-striped">
          <thead className={errors.actionPlanEffects ? 'bg-danger' : 'thead-dark'}>
            <tr>
              <th>Attribut</th>
              <th>Délai</th>
              <th>Durée de l'effet</th>
              <th>Type de modificateur</th>
              <th>Valeur du modificateur</th>
              <th scope="col" colSpan="3"></th>
            </tr>
          </thead>
          <tbody>
            {errors.actionPlanEffects
              ? <tr>
                <td colSpan={8}>
                  <ul>
                    {(errors.actionPlanEffects || []).map(err => (
                      <li className="text-danger" key={`global-error-effects-${err}`}>{err}</li>
                    ))}
                  </ul>
                </td>
              </tr>
              : (values.actionPlanEffectsAttributes)
                .filter(effect => !effect._destroy)
                .map((effect, effectIndex) => (
                  <tr key={`effect-${effect.id || `new-${effectIndex}`}`}>
                    <td>
                      <Field type="hidden" name={`actionPlanEffectsAttributes[${effectIndex}].id`} />
                      <FieldWithError
                        placeholder="Nom d'attribut"
                        className='form-control'
                        name={`actionPlanEffectsAttributes[${effectIndex}].attributeName`}
                        errorName={`actionPlanEffects[${effectIndex}].attributeName`}
                        as="select"
                      >
                        <option disabled value="">attribut</option>
                        {(layer.attributesSchema || []).map(attr => (
                          <option value={attr.attributeName} key={`attribute-${attr.attributeName}`}>{attr.attributeName}</option>
                        ))}
                      </FieldWithError>
                    </td>
                    <td> <FieldWithError placeholder="Delai" className='form-control' type="number" name={`actionPlanEffectsAttributes[${effectIndex}].delay`}
                      errorName={`actionPlanEffects[${effectIndex}].delay`} />
                    </td>
                    <td> <FieldWithError placeholder="Durée de l'effet" className='form-control' type="number"
                      name={`actionPlanEffectsAttributes[${effectIndex}].effectDuration`}
                      errorName={`actionPlanEffects[${effectIndex}].effectDuration`} />
                    </td>
                    <td>
                      <Field type="hidden" name={`actionPlanEffectsAttributes[${effectIndex}].valueModificatorAttributes.id`} />
                      <FieldWithError
                        className='form-control'
                        placeholder="Action"
                        as="select"
                        name={`actionPlanEffectsAttributes[${effectIndex}].valueModificatorAttributes.modificatorType`}
                        errorName={`actionPlanEffects[${effectIndex}].valueModificator.modificatorType`} >
                        <option value="" disabled>Action</option>
                        <option value="add">Ajouter</option>
                        <option value="replace">Remplacer</option>
                      </FieldWithError>
                    </td>
                    <td>
                      <FieldWithError
                        placeholder="Valeur du modificateur"
                        className='form-control'
                        type="number"
                        name={`actionPlanEffectsAttributes[${effectIndex}].valueModificatorAttributes.${effect.valueModificatorAttributes.modificatorType === "add"
                          ? 'addValue'
                          : 'replaceByValue'}`}
                        errorName={`actionPlanEffects[${effectIndex}].valueModificator.${effect.valueModificatorAttributes.modificatorType === "add"
                          ? 'addValue'
                          : 'replaceByValue'}`} />
                    </td>
                    <td>
                      <a onClick={() => arrayHelpers.replace(effectIndex, { _destroy: true, id: effect.id }) /* arrayHelpers.remove(effectIndex) */}><i className='fas fa-trash text-danger'></i></a>
                    </td>
                  </tr>
                ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8} className="text-right">
                <button type="button" className="btn btn-success" onClick={() => arrayHelpers.push(NEW_EFFECT)}>
                  Ajouter un effet
                    </button>
              </td>
            </tr>
          </tfoot>
        </table>
      )} />
  )
}

export default ActionPlanEffectList