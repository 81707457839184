import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { Toast } from '../Toast';

const VariableForecastForm = ({ layerId, id }) => {
  if (!layerId) {
    return (
      <div className="alert alert-danger" role="alert">
        Couche de données indéterminée !
      </div>
    );
  }
  const csrfToken = document.querySelector('[name=csrf-token]').content;
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  const [dependencyConfig, setDependencyConfig] = useState();
  const [featureAttributes, setFeatureAttributes] = useState({});
  const [dependencyAttributes, setDependencyAttributes] = useState({});
  const [dependencyId, setDependencyId] = useState();
  const [dependencyTypes, setDependencyTypes] = useState([]);
  const [initialErrors, setInitialErrors] = useState([]);
  useEffect(() => {
    axios
      .get(`/layers/${layerId}/dependencies/${id || 'new'}.json`)
      .then((res) => {
        const config = res.data;
        ['feature_attribute', 'dependency_attribute', 'dependency_id'].forEach((attr) => {
          config[attr] = config[attr] || '';
        });
        setDependencyConfig(config);
      });
  }, [id]);

  useEffect(() => {
    if (dependencyConfig) {
      setDependencyId(dependencyConfig.dependency_id);
    }
  }, [dependencyConfig]);

  useEffect(() => {
    axios
      .get(`/layers/${layerId}/features/definition.json`)
      .then((res) => {
        setFeatureAttributes(res.data);
      });
  }, [layerId]);

  useEffect(() => {
    if (dependencyId) {
      axios
        .get(`/layers/${dependencyId}/features/definition.json`)
        .then((res) => {
          setDependencyAttributes(res.data);
        });
    } else {
      setDependencyAttributes({});
    }
  }, [dependencyId]);

  useEffect(() => {
    axios
      .get('/layers.json')
      .then((res) => {
        setDependencyTypes(res.data.filter((layer) => layer.id !== layerId));
      });
  }, [layerId]);

  const save = (config) => {
    const [url, method] = config.id
      ? [`/layers/${layerId}/dependencies/${config.id}.json`, 'put']
      : [`/layers/${layerId}/dependencies.json`, 'post'];

    axios[method](url, { feature_dependency_config: config })
      .then((res) => Toast.success({ title: 'Enregistré' })
        .then(() => {
          window.location = `/layers/${layerId}/dependencies/${res.data.id}`;
        })
      )
      .catch((res) => {
        Toast.error({
          title: res.response.statusText,
        });
        if (res.response.status === 422) {
          setInitialErrors(res.response.data);
        }
      });
  };

  if (!dependencyConfig) {
    return null;
  }
  return (
    <>
      <h2 className="mb-4">
        {dependencyConfig.id
          ? `Dépendence envers ${dependencyConfig.dependency.name}`
          : 'Nouvelle dépendance'}
      </h2>
      <Formik
        initialValues={dependencyConfig}
        onSubmit={(config) => save(config)}
        enableReinitialize
        initialErrors={initialErrors}
      >
        {({ touched, errors, setFieldValue }) => (
          <Form className="row">
            <div className="col-5">
              <div className={`card ${errors.layer && 'border-danger'}`}>
                <div className={`card-header text-white bg-primary mb-3 ${errors.layer && 'border-danger'}`}>
                  <h3>{dependencyConfig.layer.name}</h3>
                </div>
                <div className="card-body">
                  <Field
                    as="select"
                    className={`form-control ${touched.feature_attribute && errors.feature_attribute ? 'is-invalid' : ''}`}
                    name="feature_attribute"
                  >
                    <option aria-label="Vide" value="" key="feature-attr-empty" />
                    {Object.keys(featureAttributes).sort().map((attr) => (
                      <option value={attr} key={`feature-${attr}`}>
                        {attr}
                      </option>
                    ))}
                  </Field>
                  {errors.feature_attribute && (
                    <div className="invalid-feedback">
                      {errors.feature_attribute}
                    </div>
                  )}
                </div>
              </div>
            </div>


            <div className="col-2 text-center my-auto">
              <div>
                Correspond à
              </div>
              <div>
                <i style={{ fontSize: '4em' }} className="fas fa-long-arrow-alt-right" />
              </div>
            </div>

            <div className="col-5">
              <div className={`card ${errors.dependency && 'border-danger'}`}>
                <div className="card-header text-white bg-warning mb-3">
                  <Field
                    as="select"
                    className={`form-control ${touched.dependency_id && errors.dependency_id ? 'is-invalid' : ''}`}
                    name="dependency_id"
                    onChange={(e) => {
                      setDependencyId(e.target.value);
                      setFieldValue('dependency_id', e.target.value);
                    }}
                  >
                    <option aria-label="Vide" value="" key="dependencyId-empty" />
                    {dependencyTypes.map((layer) => (
                      <option value={layer.id} key={`dependencyType-${layer.id}`}>
                        {layer.name}
                      </option>
                    ))}
                  </Field>
                  {errors.dependency_id && (
                    <div className="invalid-feedback">
                      {errors.dependency_id}
                    </div>
                  )}
                </div>
                <div className="card-body">
                  <Field
                    as="select"
                    className={`form-control ${touched.dependency_attribute && errors.dependency_attribute ? 'is-invalid' : ''}`}
                    name="dependency_attribute"
                  >
                    <option aria-label="Vide" value="" key="dependency-empty" />
                    {Object.keys(dependencyAttributes).sort().map((attr) => (
                      <option value={attr} key={`dependency-${attr}`}>
                        {attr}
                      </option>
                    ))}
                  </Field>
                  {errors.dependency_attribute && (
                    <div className="invalid-feedback">
                      {errors.dependency_attribute}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 text-right">
              <Button type="submit" variant="success" className="mr-2">
                {window.I18n.t('save')}
              </Button>
              <Button
                type="button"
                variant="danger"
                onClick={() => { window.location = `/layers/${layerId}/dependencies`; }}
              >
                {window.I18n.t('cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

VariableForecastForm.propTypes = {
  layerId: PropTypes.number.isRequired,
  id: PropTypes.number,
};

VariableForecastForm.defaultProps = {
  id: null,
};

export default VariableForecastForm;
