import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useField } from 'formik';
import CreatableSelect from 'react-select/creatable';

/* Sous-composant utilisé dans Features/Dependencies/Requirements/Form.jsx */ 
const FeatureVariableSelect = ({ layerId, name, onChange, formik, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const { value, touched, error, initialError } = meta;
  const { setValue } = helpers;

  const [options, setOptions] = useState([]);
  const [customOption, setCustomOption] = useState();

  useEffect(() => {
    if (layerId) {
      axios
        .get(`/layers/${layerId}/variables.json`)
        .then((res) => {
          const opt = customOption ? [{ label: `"${customOption}"`, value: customOption }] : []
          setOptions([...opt, ...res.data.map(({ id, name, variableType }) => (
            { label: name, value: id, variableType }
          ))]);
        });
    } else {
      setOptions([]);
    }
  }, [layerId, customOption]);

  return (
    <>
      <CreatableSelect
        isClearable
        className={`react-select ${(error || initialError) && 'is-invalid'}`}
        placeholder="Sélectionner"
        noOptionsMessage={() => 'Aucune option'}
        formatCreateLabel={(v) => `Créer la variable "${v}"`}
        options={options}
        {...field}
        {...props}
        onChange={option => {
          setValue(option.value)
          if (onChange) { onChange(option); }
        }}
        onCreateOption={(v) => {
          setCustomOption(v)
          setValue(v)
        }}
        value={(options ? options.find(option => option.value === value) : '')}
      />
      {
        touched && (error || initialError) && (
          <div className="invalid-feedback d-block">
            {error || initialError}
          </div>
        )
      }
    </>
  );
};

FeatureVariableSelect.propTypes = {
  layerId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    touched: PropTypes.shape(),
    errors: PropTypes.shape(),
  }).isRequired,
};

export default FeatureVariableSelect;
