import React, {useContext} from "react";
import { useState } from "react";
import { MapContext } from '../Map';

import "./ZoomIndicator.css";


const ZoomIndicator = () => {
  const map = useContext(MapContext);
  const [zoom, setZoom] = useState(map.getView().getZoom()?.toFixed(4))


  map.on('moveend', evt => setZoom(evt.map.getView().getZoom().toFixed(4)))

  return <div className="zoom-indicator">
    Zoom: {zoom}
  </div>;
};

export default ZoomIndicator;