export const threeIntervalsForecast = {
  "id": 6,
  "name": "1910-01-15",
  "issuedAt": "1910-01-15T00:00:00.000Z",
  "createdAt": "2022-10-05T10:04:22.894Z",
  "updatedAt": "2022-10-05T10:04:22.894Z",
  "forecastAlternative": {
    "id": 6,
    "name": "Scénario median",
    "createdAt": "2022-10-05T09:54:45.271Z",
    "updatedAt": "2022-10-05T09:54:45.271Z",
    "forecastableModelId": 2
  },
  "forecastableFeatures": [
    {
      "id": 46,
      "name": "Meaux",
      "forecastableModelId": 2,
      "createdAt": "2022-10-05T09:54:45.393Z",
      "updatedAt": "2022-10-05T09:54:45.393Z"
    },
    {
      "id": 47,
      "name": "Melun",
      "forecastableModelId": 2,
      "createdAt": "2022-10-05T09:54:45.401Z",
      "updatedAt": "2022-10-05T09:54:45.401Z"
    }
  ],
  "forecastableModel": {
    "id": 2,
    "name": "Hauteur d'eau",
    "forecastedType": null,
    "attributeName": "Hauteur d'eau",
    "timeIntervals": [
      {
        "to": "24",
        "from": "0"
      },
      {
        "to": "48",
        "from": "24"
      },
      {
        "to": "72",
        "from": "48"
      }
    ],
    "createdAt": "2022-10-05T09:54:45.261Z",
    "updatedAt": "2022-10-05T09:54:45.261Z",
    "intervalType": "hours"
  },
  "layerFeatureEvaluators": [
    {
      "id": 8,
      "featureVariableId": null,
      "uuid": "66a87760-65c7-4ffc-a655-af2d715999f6",
      "createdAt": "2022-10-05T09:54:45.282Z",
      "updatedAt": "2022-10-05T09:54:45.282Z",
      "config": null,
      "forecastableModelId": 2
    }
  ],
  "activeResultRegisterings": [],
  "forecastValues": [],
  "evaluationsCompleted": true,
  "evaluationsCompletedAt": "2022-10-05T11:02:57.836Z",
  "missingEvaluations": [],
  "url": "http://example.keyros.org/forecast_bulletins/6.json"
}

export const sixIntervalsForecast= {
  "id": 6,
  "name": "1910-01-15",
  "issuedAt": "1910-01-15T00:00:00.000Z",
  "createdAt": "2022-10-05T10:04:22.894Z",
  "updatedAt": "2022-10-05T10:04:22.894Z",
  "forecastAlternative": {
    "id": 6,
    "name": "Scénario median",
    "createdAt": "2022-10-05T09:54:45.271Z",
    "updatedAt": "2022-10-05T09:54:45.271Z",
    "forecastableModelId": 2
  },
  "forecastableFeatures": [
    {
      "id": 46,
      "name": "Meaux",
      "forecastableModelId": 2,
      "createdAt": "2022-10-05T09:54:45.393Z",
      "updatedAt": "2022-10-05T09:54:45.393Z"
    },
    {
      "id": 47,
      "name": "Melun",
      "forecastableModelId": 2,
      "createdAt": "2022-10-05T09:54:45.401Z",
      "updatedAt": "2022-10-05T09:54:45.401Z"
    }
  ],
  "forecastableModel": {
    "id": 2,
    "name": "Hauteur d'eau",
    "forecastedType": null,
    "attributeName": "Hauteur d'eau",
    "timeIntervals": [
      {
        "to": "24",
        "from": "0"
      },
      {
        "to": "48",
        "from": "24"
      },
      {
        "to": "72",
        "from": "48"
      },
      {
        "to": "96",
        "from": "72"
      },
      {
        "to": "120",
        "from": "96"
      },
      {
        "to": "146",
        "from": "120"
      }
    ],
    "createdAt": "2022-10-05T09:54:45.261Z",
    "updatedAt": "2022-10-05T09:54:45.261Z",
    "intervalType": "hours"
  },
  "layerFeatureEvaluators": [
    {
      "id": 8,
      "featureVariableId": null,
      "uuid": "66a87760-65c7-4ffc-a655-af2d715999f6",
      "createdAt": "2022-10-05T09:54:45.282Z",
      "updatedAt": "2022-10-05T09:54:45.282Z",
      "config": null,
      "forecastableModelId": 2
    }
  ],
  "activeResultRegisterings": [],
  "forecastValues": [],
  "evaluationsCompleted": true,
  "evaluationsCompletedAt": "2022-10-05T11:02:57.836Z",
  "missingEvaluations": [],
  "url": "http://example.keyros.org/forecast_bulletins/6.json"
}

